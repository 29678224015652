import "leaflet/dist/leaflet.css";
import { useContext, useEffect, useRef, useState } from "react";
import { Polyline, Popup, Tooltip, useMap } from "react-leaflet";
import MapContext from "../../../../context/MapContext";

const calculateAngle = (point1, point2) => {
  const deltaX = point2.lon - point1.lon;
  const deltaY = point2.lat - point1.lat;

  // Compute angle in degrees
  let angle = (Math.atan2(Math.abs(deltaY), Math.abs(deltaX)) * 180) / Math.PI;

  // Check if deltaX and deltaY have the same sign
  if ((deltaX >= 0 && deltaY >= 0) || (deltaX < 0 && deltaY < 0)) {
    angle = -angle; // Rotate left
  }

  return angle;

  // console.log("Calculated Rotation Angle:", angle, "degrees");
};

const OSMTransmission = ({ zoomLevel }) => {
  const map = useMap();
  const zoom = map.getZoom();
  const initialUpdateRef = useRef(false);
  let { OSMTransmissionLines } = useContext(MapContext);
  const [visibleLines, setVisibleLines] = useState([]);
  const [visibleVoltages, setVisibleVoltages] = useState([]);

  // Update visible voltages based on zoom level
  const updateVisibleVoltages = (zoomLevel) => {
    // All voltages
    if (zoomLevel <= 3) {
      setVisibleVoltages(["500000", "400000", "330000", "275000"]); // High voltage
    } else if (zoomLevel <= 5) {
      setVisibleVoltages([
        "500000",
        "400000",
        "330000",
        "275000",
        "220000",
        "150000",
      ]); // High voltage
    } else if (zoomLevel <= 6) {
      setVisibleVoltages([
        "500000",
        "400000",
        "330000",
        "275000",
        "220000",
        "150000",
        "132000",
      ]); // High voltage
    } else if (zoomLevel <= 8) {
      setVisibleVoltages([
        "500000",
        "400000",
        "330000",
        "275000",
        "220000",
        "150000",
        "132000",
        "110000",
        "80000",
      ]); // Medium voltage
    } else if (zoomLevel <= 9) {
      setVisibleVoltages([
        "500000",
        "400000",
        "330000",
        "275000",
        "220000",
        "150000",
        "132000",
        "110000",
        "80000",
        "66000",
      ]); // Medium voltage
    } else if (zoomLevel <= 13) {
      setVisibleVoltages([
        "500000",
        "400000",
        "330000",
        "275000",
        "220000",
        "150000",
        "132000",
        "110000",
        "80000",
        "66000",
        "33000",
        "22000",
      ]); // Medium voltage
    } else if (zoomLevel <= 15) {
      setVisibleVoltages([
        "500000",
        "400000",
        "330000",
        "275000",
        "220000",
        "150000",
        "132000",
        "110000",
        "80000",
        "66000",
        "33000",
        "22000",
        "11000",
      ]); // Medium voltage
    } else {
      setVisibleVoltages([
        "500000",
        "400000",
        "330000",
        "275000",
        "220000",
        "150000",
        "132000",
        "110000",
        "80000",
        "66000",
        "33000",
        "22000",
        "11000",
      ]); // All voltages
    }
  };

  // Filter lines by viewport and voltage
  const filterLines = (bounds) => {
    return OSMTransmissionLines.filter(
      (line) =>
        visibleVoltages.includes(
          line.tags?.voltage?.includes(";")
            ? line.tags?.voltage.split(";")[0]
            : line.tags?.voltage
        ) &&
        line.geometry.some((point) => bounds.contains([point.lat, point.lon]))
    );
  };

  // Monitor map viewport and update visible lines
  const MapViewportListener = () => {
    useEffect(() => {
      if (OSMTransmissionLines.length > 0) {
        const updateVisibleLines = () => {
          const bounds = map.getBounds();
          const zoom = map.getZoom();
          updateVisibleVoltages(zoom);
          const filteredLines = filterLines(bounds);
          setVisibleLines(filteredLines);
        };

        map.on("moveend", updateVisibleLines);
        map.on("zoomend", updateVisibleLines);

        if (!initialUpdateRef.current) {
          // Perform initial update only once
          updateVisibleLines();
          initialUpdateRef.current = true;
        }

        return () => {
          map.off("moveend", updateVisibleLines);
          map.off("zoomend", updateVisibleLines);
        };
      }
    }, [map]);
    return null;
  };

  // Function to style the lines
  const getLineStyle = (line, zoom) => {
    // const voltage = parseInt(line.tags?.voltage, 10) || 0;
    const voltageStr = line.tags?.voltage;
    const voltage = voltageStr
      ? parseInt(voltageStr.split(";")[0], 10) || 0
      : 0;

    const line_type = line.tags?.power;
    const frequency = line.tags?.frequency;
    const line_substation = line.tags?.line;
    let color;
    let weight;

    if (voltage >= 400000) {
      color = "#7326D3"; // "#B59F11";
      weight = 2;
    } else if (voltage >= 330000) {
      color = "#B54EB2"; // "#B59F11";
      weight = 2;
    } else if (voltage >= 275000) {
      color = "#C73030"; //"#F2AA2E";
      weight = 2;
    } else if (voltage >= 220000) {
      color = "#E55C00"; //"#E5007E";
      weight = 1.5;
    } else if (voltage >= 132000) {
      color = "#B55D00"; //"#0100FF";
      weight = 1.5;
    } else if (voltage >= 66000) {
      color = "#B59F11";
      weight = 1;
    } else if (voltage >= 33000) {
      color = "#56B555";
      weight = 1;
    } else if (voltage >= 22000) {
      color = "#6F97B8";
      weight = 1;
    } else {
      color = "#7A7A85";
      weight = 1;
    }

    if (frequency === "0") {
      color = "#4F00B5";
      weight = 2;
    }

    if (line_substation === "bay" || line_substation === "busbar") {
      weight = 0.5;
    }

    if (line_type === "cable") {
      return {
        color: color,
        weight: weight * (zoom / 10),
        opacity: 1,
        dashArray: zoom > 10 ? "10, 10" : "10, 5",
      };
    } else if (line_type === "construction") {
      return {
        color: color,
        weight: weight * (zoom / 10),
        opacity: 0.6,
      };
    } else {
      return {
        color: color,
        weight: weight * (zoom / 10),
        opacity: 1,
      };
    }
  };

  const offsetPolyline = (positions, offset) => {
    return positions.map((point, index, arr) => {
      if (index === arr.length - 1) return point; // Skip last point

      const nextPoint = arr[index + 1];
      const dx = nextPoint[1] - point[1]; // Longitude difference
      const dy = nextPoint[0] - point[0]; // Latitude difference
      const length = Math.sqrt(dx * dx + dy * dy);

      if (length === 0) return point; // Avoid division by zero

      // Compute unit perpendicular vector
      const perpX = (-dy / length) * offset;
      const perpY = (dx / length) * offset;

      return [point[0] + perpY, point[1] + perpX];
    });
  };

  const renderLines = () => {
    return visibleLines.map((line) => {
      if (line.geometry && line.geometry.length >= 2) {
        const positions = line.geometry.map((point) => [point.lat, point.lon]);
        const { operator, voltage, name, wires, circuits = 1, ref } = line.tags;
        const voltageKV = `${(parseInt(voltage, 10) || 0) / 1000} kV`;

        const tagName =
          name && line.tags?.frequency === "0" ? `${name}` : voltageKV;

        const zoom = map.getZoom(); // Get current zoom level

        // Adjust step size based on zoom level (higher zoom = more labels)
        const stepSize = zoom < 10 ? 6 : zoom < 12 ? 4 : zoom < 14 ? 3 : 1;

        // Adjust minimum distance threshold (higher zoom = smaller threshold)
        const minDistanceThreshold =
          zoom < 10 ? 0.01 : zoom < 12 ? 0.005 : zoom < 14 ? 0.0025 : 0.0015;

        // Select multiple points along the line for labeling
        const labelPositions = [];

        for (let i = 0; i < line.geometry.length - 1; i += stepSize) {
          const point1 = line.geometry[i];
          const point2 = line.geometry[i + 1];

          // Calculate distance
          const deltaX = point2.lon - point1.lon;
          const deltaY = point2.lat - point1.lat;
          const distance = Math.sqrt(deltaX * deltaX + deltaY * deltaY);

          // Only add label if distance is above threshold
          if (distance > minDistanceThreshold) {
            const midPoint = {
              lat: (point1.lat + point2.lat) / 2,
              lon: (point1.lon + point2.lon) / 2,
            };
            const angle = calculateAngle(point1, point2);

            labelPositions.push({
              position: [midPoint.lat, midPoint.lon],
              angle,
            });
          }
        }

        if (circuits > 1 && zoom > 13) {
          const offsetDistance = 0.00075 / (zoom / 10) ** 5; // Adjust spacing based on zoom

          // Generate multiple parallel lines
          return Array.from({ length: circuits }, (_, i) => {
            const offset = (i - (circuits - 1) / 2) * offsetDistance; // Center the lines
            const offsetPositions =
              offset !== 0 ? offsetPolyline(positions, offset) : positions;

            return (
              <Polyline
                key={`${line.id}-${i}`}
                positions={offsetPositions}
                pathOptions={getLineStyle(line, zoomLevel)}
                eventHandlers={{
                  click: () => {
                    console.log(line);
                  },
                }}
              >
                {/* Multiple labels along the line when zoom >= 13 */}
                {zoomLevel >= 8 &&
                  labelPositions.map((label, index) => (
                    <Tooltip
                      key={`${line.id}-ind-${index}`}
                      permanent
                      direction="center"
                      offset={[10, 10]}
                      className="custom-tooltip"
                    >
                      <div
                        style={{
                          // transform: `rotate(${label.angle}deg)`, // Rotate label
                          fontSize: "8px", // Smaller font size
                          padding: "1px 2px", // Less padding for compactness
                          background: "rgba(220, 220, 220, 0.7)", // More subtle gray background with slight transparency
                          border: "none", // No border
                          borderRadius: "2px", // Slightly smaller border radius
                          color: "#333", // Darker text color for better visibility
                          textAlign: "center",
                        }}
                      >
                        {!(line.tags.power == "construction")
                          ? tagName
                          : `${tagName} (in construction)`}
                      </div>
                    </Tooltip>
                  ))}
                <Popup>
                  <div>
                    <strong>NAME:</strong> {name} <br />
                    <strong>OPERATING_VOLTAGE:</strong> {voltageKV} <br />
                    <strong>OWNER:</strong> {operator || ""} <br />
                    <strong>TYPE:</strong> {line.tags.line || "line"} <br />
                    <strong>CIRCUITS:</strong> {circuits || ""} <br />
                    <strong>WIRES:</strong> {wires || ""} <br />
                    <strong>REFERENCE:</strong> {ref || ""} <br />
                  </div>
                </Popup>
              </Polyline>
            );
          });
        } else {
          return (
            <Polyline
              key={`${line.id}-${zoomLevel}`}
              positions={positions}
              pathOptions={getLineStyle(line, zoomLevel)}
              eventHandlers={{
                click: () => {
                  console.log(line);
                },
              }}
            >
              {/* Multiple labels along the line when zoom >= 13 */}
              {zoomLevel >= 8 &&
                labelPositions.map((label, index) => (
                  <Tooltip
                    key={`${line.id}-ind-${index}`}
                    permanent
                    direction="center"
                    offset={[10, 10]}
                    className="custom-tooltip"
                  >
                    <div
                      style={{
                        // transform: `rotate(${label.angle}deg)`, // Rotate label
                        fontSize: !line.tags.name ? "8px" : "10px", // Smaller font size
                        padding: "1px 2px", // Less padding for compactness
                        background: "rgba(220, 220, 220, 0.7)", // More subtle gray background with slight transparency
                        border: "none", // No border
                        borderRadius: "2px", // Slightly smaller border radius
                        color: "#333", // Darker text color for better visibility
                        textAlign: "center",
                      }}
                    >
                      {!(line.tags.power == "construction")
                        ? tagName
                        : `${tagName} (in construction)`}
                    </div>
                  </Tooltip>
                ))}

              <Popup>
                <div>
                  <strong>NAME:</strong> {name} <br />
                  <strong>OPERATING_VOLTAGE:</strong> {voltageKV} <br />
                  <strong>OWNER:</strong> {operator || ""} <br />
                  <strong>TYPE:</strong> {line.tags.line || "line"} <br />
                  <strong>CIRCUITS:</strong> {circuits || ""} <br />
                  <strong>WIRES:</strong> {wires || ""} <br />
                  <strong>REFERENCE:</strong> {ref || ""} <br />
                </div>
              </Popup>
            </Polyline>
          );
        }
      }
      return null;
    });
  };

  return (
    <>
      <MapViewportListener />
      {renderLines()}
    </>
  );
};

export default OSMTransmission;
