import { Icon } from "leaflet";
import "leaflet/dist/leaflet.css";
import { useContext, useEffect, useState } from "react";
import { useMap } from "react-leaflet";
import AuthContext from "../../../../context/AuthContext";
import MapContext from "../../../../context/MapContext";
import GeoJSONLayer from "./GeoJSONLayer";

// Define custom icons for each fuel type
const getFuelIcon = (fuelType) => {
  let iconUrl;

  switch (fuelType) {
    case "coal":
      iconUrl = "https://cdn-icons-png.flaticon.com/512/3327/3327023.png";
      break;
    case "solar":
    case "solar;battery":
      iconUrl = "https://cdn-icons-png.flaticon.com/512/2511/2511664.png";
      break;
    case "wind":
      iconUrl = "https://cdn-icons-png.flaticon.com/512/9367/9367346.png";
      break;
    case "gas":
    case "gas;diesel":
    case "diesel;gas":
    case "abandoned_mine_methane":
    case "gas;oil":
    case "coalbed_methane":
      iconUrl = "https://cdn-icons-png.flaticon.com/512/4535/4535728.png";
      break;
    case "hydro":
      iconUrl = "https://cdn-icons-png.flaticon.com/512/1946/1946138.png";
      break;
    case "biogas":
      iconUrl = "https://cdn-icons-png.flaticon.com/512/5451/5451263.png";
      break;
    case "biofuel":
    case "biomass":
      iconUrl = "https://cdn-icons-png.flaticon.com/512/11049/11049767.png";
      break;
    case "diesel":
    case "oil":
      iconUrl = "https://cdn-icons-png.flaticon.com/512/4515/4515613.png";
      break;
    case "battery":
      iconUrl = "https://cdn-icons-png.flaticon.com/512/3463/3463455.png";
      break;
    default:
      iconUrl = "https://cdn-icons-png.flaticon.com/512/3540/3540110.png";
  }

  return new Icon({
    iconUrl,
    iconSize: [16, 16],
    iconAnchor: [8, 16],
    popupAnchor: [0, -16],
  });
};

const PowerStations = () => {
  const {
    powerStations,
    powerStationsBoundaries,
    NEMAssetsOnly,
    stationToOSMMap,
    DUIDDetails,
    getDUIDDetails,
    getStationToOSMMap,
  } = useContext(MapContext);
  let { authTokens } = useContext(AuthContext);
  const [filteredPowerStations, setFilteredPowerStations] = useState({
    type: "FeatureCollection",
    features: [],
  });
  const map = useMap();

  useEffect(() => {
    if (!Array.isArray(stationToOSMMap) || stationToOSMMap.length <= 1) {
      getStationToOSMMap();
    }

    if (!Array.isArray(DUIDDetails) || DUIDDetails.length <= 1) {
      getDUIDDetails();
    }
  }, [authTokens]);

  const filterOSMData = (osmData, stationToOSM) => {
    if (!osmData?.features || !Array.isArray(osmData.features)) {
      console.warn("Invalid osmData.features!", osmData);
      return [];
    }

    const validOSMIDs = new Set(stationToOSM.map((station) => station.OSMID));

    return osmData.features.filter((feature) =>
      validOSMIDs.has(String(feature.properties.id))
    );
  };

  useEffect(() => {
    if (!powerStations) return;

    const powerStationsCloned = JSON.parse(JSON.stringify(powerStations));

    if (NEMAssetsOnly) {
      // Example usage inside a component
      const filteredOSMData = filterOSMData(
        powerStationsCloned,
        stationToOSMMap
      );
      setFilteredPowerStations({
        type: "FeatureCollection",
        features: filteredOSMData,
      });
    } else {
      setFilteredPowerStations(powerStationsCloned);
    }
  }, [powerStations, NEMAssetsOnly, stationToOSMMap]);

  // Function to update tooltips dynamically when zooming in/out
  useEffect(() => {
    if (!(filteredPowerStations.features?.length > 0)) return;

    const updateTooltips = () => {
      const zoom = map.getZoom();

      filteredPowerStations.features?.forEach((feature) => {
        if (!feature._layer) return;

        const layer = feature._layer;
        const output = feature.properties.plantOutput || "";
        const cleanedOutput = output.endsWith(" MW")
          ? output.replace(" MW", "")
          : null;
        const parsedOutput = cleanedOutput ? parseFloat(cleanedOutput) : null;

        const zoomThreshold =
          parsedOutput >= 800
            ? 8
            : parsedOutput >= 500
            ? 9
            : parsedOutput >= 200
            ? 10
            : parsedOutput >= 100
            ? 11
            : parsedOutput >= 50
            ? 12
            : 13;

        // Get the centroid for polygons or directly use latlng for point features
        let centroid;
        if (feature.geometry.type === "Polygon") {
          // For Polygon, get centroid from bounds
          centroid = layer.getBounds().getCenter();
        } else {
          // For Point, get the latlng directly
          centroid = layer.getLatLng();
        }

        // Add label if zoom level is above the threshold
        const isInBounds = map.getBounds().contains(centroid);

        if (
          zoom >= zoomThreshold &&
          isInBounds &&
          feature.geometry.type === "Point"
        ) {
          // Ensure tooltip is displayed
          layer
            .bindTooltip(
              `<strong>${feature.properties.name} ${feature.properties.plantOutput}</strong>`,
              {
                permanent: true,
                offset: [0, -20],
                direction: "top",
                className: "powerstation-label",
              }
            )
            .openTooltip();
        } else {
          // Remove tooltip when zooming out
          layer.unbindTooltip();
        }
      });
    };

    map.on("zoomend", updateTooltips);
    updateTooltips(); // Run initially

    return () => {
      map.off("zoomend", updateTooltips);
    };
  }, [map, filteredPowerStations]);

  return (
    <>
      {filteredPowerStations.features?.length > 0 && (
        <GeoJSONLayer
          key={filteredPowerStations.features?.length}
          data={filteredPowerStations}
          map={map}
          isPowerStation={true}
          // onEachFeature={onEachFeature}
        />
      )}

      {powerStationsBoundaries?.features?.length > 0 && (
        <GeoJSONLayer
          key={powerStationsBoundaries.features?.length}
          data={powerStationsBoundaries}
          map={map}
          isPowerStation={false}
        />
      )}
    </>
  );
};

export default PowerStations;
