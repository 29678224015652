import L from "leaflet";
import "leaflet/dist/leaflet.css";
import { useContext, useEffect, useState } from "react";
import { GeoJSON, useMap } from "react-leaflet";
import MapContext from "../../../../context/MapContext";
const DCIcon = process.env.PUBLIC_URL + "/assets/icons/converter.svg";

const Substations = () => {
  let { substations } = useContext(MapContext);
  const [visibleSubstations, setVisibleSubstations] = useState({
    type: "FeatureCollection",
    features: [],
  });
  const [visibleVoltages, setVisibleVoltages] = useState([]);
  const map = useMap();

  const filterSubstations = () => {
    const zoom = map.getZoom();

    // Collect IDs of all substations with polygons
    const polygonSubstationIds = new Set(
      substations.features
        ?.filter((feature) => feature.geometry?.type === "Polygon")
        .map((feature) => String(feature.properties?.id)) // Store the original substation IDs
    );

    return (
      substations.features?.filter((feature) => {
        const isPolygon = feature.geometry?.type === "Polygon";
        const isPoint = feature.geometry?.type === "Point";
        const featureId = String(feature.properties?.id); // Convert to string
        const isDuplicatePoint =
          isPoint && polygonSubstationIds.has(featureId.replace("-point", ""));

        if (zoom > 13) {
          return isPolygon || (!isDuplicatePoint && isPoint); // Keep polygons, but filter duplicate points
        }

        return (
          !isPolygon &&
          visibleVoltages.includes(feature.properties?.voltage?.toString())
        ); // Keep everything for zoom <= 16
      }) || []
    );
  };

  const updateVisibleVoltages = (zoomLevel) => {
    if (zoomLevel <= 6) {
      setVisibleVoltages([]);
    } else if (zoomLevel <= 7) {
      setVisibleVoltages(["500000", "400000", "330000", "275000"]);
    } else if (zoomLevel <= 8) {
      setVisibleVoltages([
        "500000",
        "400000",
        "330000",
        "275000",
        "220000",
        "150000",
      ]);
    } else if (zoomLevel <= 9) {
      setVisibleVoltages([
        "500000",
        "400000",
        "330000",
        "275000",
        "220000",
        "150000",
        "132000",
      ]);
    } else if (zoomLevel <= 10) {
      setVisibleVoltages([
        "500000",
        "400000",
        "330000",
        "275000",
        "220000",
        "150000",
        "132000",
        "110000",
      ]);
    } else if (zoomLevel <= 11) {
      setVisibleVoltages([
        "500000",
        "400000",
        "330000",
        "275000",
        "220000",
        "150000",
        "132000",
        "110000",
        "66000",
        "0",
      ]);
    } else if (zoomLevel <= 13) {
      setVisibleVoltages([
        "500000",
        "400000",
        "330000",
        "275000",
        "220000",
        "150000",
        "132000",
        "110000",
        "66000",
        "33000",
        "22000",
        "0",
      ]);
    } else if (zoomLevel <= 15) {
      setVisibleVoltages([
        "500000",
        "400000",
        "330000",
        "275000",
        "220000",
        "150000",
        "132000",
        "110000",
        "66000",
        "33000",
        "22000",
        "11000",
        "0",
      ]);
    } else {
      setVisibleVoltages([
        "500000",
        "400000",
        "330000",
        "275000",
        "220000",
        "150000",
        "132000",
        "110000",
        "66000",
        "33000",
        "22000",
        "11000",
        "0",
      ]);
    }
  };

  // Monitor map viewport and update visible lines
  useEffect(() => {
    const filteredSubstations = filterSubstations();
    setVisibleSubstations({
      type: "FeatureCollection",
      features: filteredSubstations,
    });
  }, [visibleVoltages]);

  useEffect(() => {
    if (!substations.features) return;

    const updateVisibleSubstations = () => {
      const bounds = map.getBounds();
      const zoom = map.getZoom();

      updateVisibleVoltages(zoom);

      // Manually trigger visibleSubstations update
      setVisibleSubstations({
        type: "FeatureCollection",
        features: filterSubstations(),
      });
    };

    map.on("moveend", updateVisibleSubstations);
    map.on("zoomend", updateVisibleSubstations);
    map.on("zoomend", updateTooltips);

    return () => {
      map.off("moveend", updateVisibleSubstations);
      map.off("zoomend", updateVisibleSubstations);
      map.off("zoomend", updateTooltips);
    };
  }, [map, substations, visibleVoltages]); // ✅ Added `visibleVoltages`

  const getMarkerSize = (zoom, voltage) => {
    const baseSize =
      voltage / 1000 <= 66
        ? 1
        : voltage / 1000 <= 132
        ? 1
        : voltage / 1000 <= 220
        ? 1
        : voltage / 1000 <= 275
        ? 2
        : voltage / 1000 <= 330
        ? 2
        : voltage / 1000 <= 500
        ? 3
        : 20;
    return baseSize * (zoom / 10) ** 2;
  };

  const getSubstationColor = (voltage) => {
    if (voltage >= 400000) {
      return { color: "#7326D3", weight: 3.5 };
    } else if (voltage >= 330000) {
      return { color: "#B54EB2", weight: 3 };
    } else if (voltage >= 275000) {
      return { color: "#C73030", weight: 2.5 };
    } else if (voltage >= 220000) {
      return { color: "#E55C00", weight: 2.5 };
    } else if (voltage >= 132000) {
      return { color: "#B55D00", weight: 2 };
    } else if (voltage >= 66000) {
      return { color: "#B59F11", weight: 1.5 };
    } else if (voltage >= 33000) {
      return { color: "#56B555", weight: 1 };
    } else if (voltage >= 22000) {
      return { color: "#6F97B8", weight: 1 };
    } else {
      return { color: "#7A7A85", weight: 1 };
    }
  };

  const updateTooltips = () => {
    const zoom = map.getZoom();
    const threshold = 10;

    visibleSubstations.features.forEach((feature) => {
      const layer = feature._layer;
      if (layer) {
        if (zoom >= threshold) {
          layer.bindTooltip(feature.properties.name, {
            permanent: true,
            direction: "top",
            className: "substation-tooltip",
          });
        } else {
          layer.unbindTooltip(); // Remove tooltips when zoomed out
        }
      }
    });
  };

  // Add an event listener for zoom changes

  return (
    <GeoJSON
      key={visibleSubstations.features.length} // Forces re-render when data changes
      data={visibleSubstations}
      pointToLayer={(feature, latlng) => {
        const zoom = map.getZoom();

        // Get color and weight from the voltage
        const { color, weight } = getSubstationColor(
          feature.properties.voltage
        );

        // Calculate radius dynamically based on zoom level and feature properties (voltage)
        const radius = getMarkerSize(zoom, feature.properties.voltage);

        if (feature.geometry.type === "Polygon") {
          return L.polygon(feature.geometry.coordinates[0], {
            color: color, // Apply the correct color
            weight: weight, // Apply the correct weight
            fillOpacity: 0.5, // Fill opacity for the polygon
          }).setStyle({
            // Optional: Apply additional styles for circle markers
            color: color,
            weight: weight,
            radius: radius,
          });
        } else {
          if (
            (feature.properties.substation === "converter" ||
              feature.properties.substation === "transmission;converter") &&
            map.getZoom() > 9
          ) {
            // Create a new marker with the custom icon
            const customIcon = new L.Icon({
              iconUrl: DCIcon, // Make sure DCIcon is a valid image URL or reference
              iconSize: [20, 20],
              iconAnchor: [10, 10],
              popupAnchor: [0, -20],
            });

            // Return the marker with the custom icon at the latlng position
            return L.marker(latlng, { icon: customIcon });
          } else {
            return L.circleMarker(latlng, {
              color: color, // Apply the correct color
              weight: weight, // Apply the correct weight
              radius: radius, // Set radius dynamically
              fillOpacity: 0.8, // Fill opacity for the circle marker
            });
          }
        }
      }}
      style={(feature) => {
        const zoom = map.getZoom();
        // Get color and weight from the voltage
        const { color, weight } = getSubstationColor(
          feature.properties.voltage
        );
        // Calculate radius dynamically based on zoom level and feature properties (voltage)
        const radius = getMarkerSize(zoom, feature.properties.voltage);
        if (feature.geometry.type === "Polygon") {
          return {
            color: "black", // Border color (outline)
            fillColor: color, // Fill color based on voltage
            weight: weight,
            fillOpacity: 0.4, // Transparency inside the polygon
          };
        } else {
          return {
            color: color, // Apply the correct color
            weight: weight, // Apply the correct weight
            radius: radius, // Set radius dynamically
          };
        }
      }}
      onEachFeature={(feature, layer) => {
        let zoomThreshold;
        const voltage = feature.properties.voltage / 1000; // Convert voltage to kV

        // Set zoom thresholds based on voltage
        if (voltage >= 330) {
          zoomThreshold = 10;
        } else if (voltage >= 100) {
          zoomThreshold = 12;
        } else if (voltage >= 66) {
          zoomThreshold = 14;
        } else {
          zoomThreshold = 16;
        }

        // Add a click event listener
        layer.on("click", () => {
          console.log("Clicked feature properties:", feature.properties);
        });

        layer.bindPopup(`
      <div>
        <strong>Substation Name:</strong> ${feature.properties.name}<br/>
        <strong>Voltage (kV):</strong> ${feature.properties.voltage / 1000}<br/>
      </div>
    `);

        // Get the centroid for polygons or directly use latlng for point features
        let centroid;
        if (feature.geometry.type === "Polygon") {
          // For Polygon, get centroid from bounds
          centroid = layer.getBounds().getCenter();
        } else {
          // For Point, get the latlng directly
          centroid = layer.getLatLng();
        }

        // Add label if zoom level is above the threshold
        const isInBounds = map.getBounds().contains(centroid);
        const refText = feature.properties?.ref || "";

        // Create a single tooltip that contains both the name and reference (if available)
        if (map.getZoom() >= zoomThreshold && isInBounds) {
          let tooltipText = `<strong>${feature.properties.name}</strong>`; // Always show the name

          if (map.getZoom() >= 16 && refText) {
            tooltipText += `<br/><em>Ref: ${refText}</em>`; // Add reference if zoom > 16
          }

          layer.bindTooltip(tooltipText, {
            permanent: true,
            direction: "bottom",
            offset: [0, 5],
            className: "substation-label",
          });
        }
      }}
    />
  );
};

export default Substations;
