import { Drawer } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import L, { Icon } from "leaflet";
import "leaflet/dist/leaflet.css";
import { useContext, useEffect, useState } from "react";
import { GeoJSON } from "react-leaflet";
import MapContext from "../../../../context/MapContext";

const getFuelIcon = (fuelType) => {
  let iconUrl;

  switch (fuelType) {
    case "coal":
      iconUrl = "https://cdn-icons-png.flaticon.com/512/3327/3327023.png";
      break;
    case "solar":
    case "solar;battery":
      iconUrl = "https://cdn-icons-png.flaticon.com/512/2511/2511664.png";
      break;
    case "wind":
      iconUrl = "https://cdn-icons-png.flaticon.com/512/9367/9367346.png";
      break;
    case "gas":
    case "gas;diesel":
    case "diesel;gas":
    case "abandoned_mine_methane":
    case "gas;oil":
    case "coalbed_methane":
      iconUrl = "https://cdn-icons-png.flaticon.com/512/4535/4535728.png";
      break;
    case "hydro":
      iconUrl = "https://cdn-icons-png.flaticon.com/512/1946/1946138.png";
      break;
    case "biogas":
      iconUrl = "https://cdn-icons-png.flaticon.com/512/5451/5451263.png";
      break;
    case "biofuel":
    case "biomass":
      iconUrl = "https://cdn-icons-png.flaticon.com/512/11049/11049767.png";
      break;
    case "diesel":
    case "oil":
      iconUrl = "https://cdn-icons-png.flaticon.com/512/4515/4515613.png";
      break;
    case "battery":
      iconUrl = "https://cdn-icons-png.flaticon.com/512/3463/3463455.png";
      break;
    default:
      iconUrl = "https://cdn-icons-png.flaticon.com/512/3540/3540110.png";
  }

  return new Icon({
    iconUrl,
    iconSize: [16, 16],
    iconAnchor: [8, 16],
    popupAnchor: [0, -16],
  });
};

const GeoJSONLayer = ({ data, map, isPowerStation }) => {
  const { stationToOSMMap, DUIDDetails } = useContext(MapContext);
  const [selectedFeature, setSelectedFeature] = useState(null);
  const [open, setOpen] = useState(false);

  const columns = [
    { field: "DUID", headerName: "DUID", flex: 1, width: 50 },
    { field: "REGIONID", headerName: "Region", flex: 1, width: 50 },
    { field: "START_DATE", headerName: "Start Date", flex: 1, width: 50 },
    { field: "PARTICIPANTID", headerName: "Participant", flex: 1, width: 50 },
    { field: "DISPATCHTYPE", headerName: "Dispatch Type", flex: 1, width: 50 },
    { field: "SCHEDULE_TYPE", headerName: "Schedule Type", flex: 1, width: 50 },
    {
      field: "AGCCAPABILITY",
      headerName: "AGC Capability",
      flex: 1,
      width: 50,
    },

    {
      field: "CONNECTIONPOINTID",
      headerName: "Connection Point ID",
      flex: 1,
      width: 50,
    },
    {
      field: "VOLTLEVEL",
      headerName: "Voltage (kV)",
      flex: 1,
      width: 50,
    },
    {
      field: "MAXCAPACITY",
      headerName: "Max Capacity (MW)",
      flex: 1,
      width: 50,
    },
    {
      field: "REGISTEREDCAPACITY",
      headerName: "Registered Capacity (MW)",
      flex: 1,
      width: 50,
    },
    {
      field: "DISTRIBUTIONLOSSFACTOR",
      headerName: "Distribution Loss Factor",
      flex: 1,
      width: 50,
    },
    {
      field: "TRANSMISSIONLOSSFACTOR",
      headerName: "Transmission Loss Factor",
      flex: 1,
      width: 50,
    },
    {
      field: "CO2E_ENERGY_SOURCE",
      headerName: "CO2 Emissions Factor",
      flex: 1,
      width: 50,
    },
  ];

  const [geoJSONKey, setGeoJSONKey] = useState(0);

  useEffect(() => {
    setGeoJSONKey(`${stationToOSMMap.length}-${DUIDDetails.length}`);
  }, [stationToOSMMap, DUIDDetails]);

  // Function to update tooltips dynamically when zooming in/out
  useEffect(() => {
    if (!(data.features?.length > 0)) return;

    const updateTooltips = () => {
      const zoom = map.getZoom();

      data.features?.forEach((feature) => {
        if (!feature._layer) return;

        const layer = feature._layer;
        const output = feature.properties.plantOutput || "";
        const cleanedOutput = output.endsWith(" MW")
          ? output.replace(" MW", "")
          : null;
        const parsedOutput = cleanedOutput ? parseFloat(cleanedOutput) : null;

        const zoomThreshold =
          parsedOutput >= 800
            ? 8
            : parsedOutput >= 500
            ? 9
            : parsedOutput >= 200
            ? 10
            : parsedOutput >= 100
            ? 11
            : parsedOutput >= 50
            ? 12
            : 13;

        // Get the centroid for polygons or directly use latlng for point features
        let centroid;
        if (feature.geometry.type === "Polygon") {
          // For Polygon, get centroid from bounds
          centroid = layer.getBounds().getCenter();
        } else {
          // For Point, get the latlng directly
          centroid = layer.getLatLng();
        }

        // Add label if zoom level is above the threshold
        const isInBounds = map.getBounds().contains(centroid);

        if (
          zoom >= zoomThreshold &&
          isInBounds &&
          feature.geometry.type === "Point"
        ) {
          // Ensure tooltip is displayed
          layer
            .bindTooltip(
              `<strong>${feature.properties.name} ${feature.properties.plantOutput}</strong>`,
              {
                permanent: true,
                offset: [0, -20],
                direction: "top",
                className: "powerstation-label",
              }
            )
            .openTooltip();
        } else {
          // Remove tooltip when zooming out
          layer.unbindTooltip();
        }
      });
    };

    map.on("zoomend", updateTooltips);
    updateTooltips(); // Run initially

    return () => {
      map.off("zoomend", updateTooltips);
    };
  }, [map, data]);

  const onEachFeature = (feature, layer) => {
    if (!stationToOSMMap.length || !DUIDDetails.length) return;
    if (!feature.properties) return;

    const osmID = feature.properties.id;
    const stationEntries = stationToOSMMap.filter(
      (s) => s.OSMID === String(osmID)
    );
    // if (!stationEntries.length) return;

    const duids = stationEntries.map((s) => s.DUID);
    const duidDetails = DUIDDetails.filter((d) => duids.includes(d.DUID));

    if (duidDetails.length > 0) {
      layer.on("click", () => {
        setSelectedFeature({
          name: feature.properties.name,
          plantOutput: feature.properties.plantOutput || "N/A",
          details: duidDetails,
        });
        setOpen(true);
      });
    } else {
      // Bind the popup
      layer.bindPopup(`
          <div>
            <strong>Power Plant Name:</strong> ${
              feature.properties.name || ""
            } <br/>
            <strong>Operator:</strong> ${
              feature.properties.operator || ""
            } <br/>
            <strong>Power:</strong> ${
              feature.properties.plantOutput || ""
            } <br/>
            <strong>Fuel Type:</strong> ${
              feature.properties.plantSource || "Unknown"
            } <br/>
            <strong>Start Date:</strong> ${
              feature.properties.start_date || ""
            } <br/>
            <strong>Status:</strong> ${
              feature.properties.plantStatus || ""
            } <br/>
            <strong>Reference:</strong> ${feature.properties.ref || ""} <br/>
          </div>
        `);
    }

    // Add a click event listener
    layer.on("click", () => {
      console.log("Clicked feature properties:", feature.properties);
    });

    const output = parseFloat(
      (feature.properties.plantOutput || "").replace(" MW", "")
    );
    const zoomThreshold =
      output >= 800
        ? 10
        : output >= 500
        ? 12
        : output >= 300
        ? 14
        : output >= 200
        ? 15
        : output >= 100
        ? 16
        : 18;

    // Get the centroid for polygons or directly use latlng for point features
    let centroid;
    if (feature.geometry.type === "Polygon") {
      centroid = layer.getBounds().getCenter();
    } else {
      centroid = layer.getLatLng();
    }

    // Add label if zoom level is above the threshold
    const isInBounds = map.getBounds().contains(centroid);
    if (
      map.getZoom() >= zoomThreshold &&
      isInBounds &&
      feature.geometry.type === "Point"
    ) {
      layer.bindTooltip(
        `<strong>${feature.properties.name} ${feature.properties.plantOutput}</strong>`,
        {
          permanent: true,
          direction: "top",
          offset: [15, 0],
          className: "powerstation-label",
        }
      );
    }

    // Store layer reference in the feature
    feature._layer = layer;
  };

  return (
    <>
      <GeoJSON
        key={geoJSONKey}
        data={data}
        pointToLayer={(feature, latlng) => {
          const fuelType = feature.properties.plantSource || "Unknown";
          return L.marker(latlng, { icon: getFuelIcon(fuelType) });
        }}
        style={(feature) => {
          if (feature.geometry.type === "Polygon") {
            const status = feature.properties.plantStatus || "";
            return {
              color: "black",
              fillColor: status === "Operating" ? "green" : "grey",
              weight: 1,
              opacity: 0.2,
              fillOpacity: 0.1,
            };
          }
          return {};
        }}
        onEachFeature={onEachFeature}
      />
      {/* Bottom Drawer Popup */}
      <Drawer
        key={`drawer-${geoJSONKey}`}
        anchor="bottom"
        open={open}
        onClose={() => setOpen(false)}
        PaperProps={{ style: { height: "50vh", padding: "16px" } }}
      >
        <h2>{selectedFeature?.name}</h2>
        <p>Plant Output: {selectedFeature?.plantOutput}</p>
        <div style={{ height: "100%", width: "100%" }}>
          <DataGrid
            rows={
              selectedFeature?.details?.map((row, index) => ({
                id: index,
                ...row,
              })) || []
            }
            rowHeight={38}
            columns={columns}
            pageSize={5}
            autoHeight
          />
        </div>
      </Drawer>
    </>
  );
};

export default GeoJSONLayer;
