import SettingsIcon from "@mui/icons-material/Settings";
import { alpha, Switch, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Fab from "@mui/material/Fab";
import Popover from "@mui/material/Popover";
import { makeStyles, styled, useTheme } from "@mui/styles";
import { useContext, useState } from "react";
import MapContext from "../../../context/MapContext";
import { tokens } from "../../../theme";
import MapConfiguration from "./legend/MapConfiguration";

// Custom styles for positioning the floating button and legend items
const useStyles = makeStyles(() => ({
  fab: {
    position: "fixed",
    top: "8vh",
    right: 20,
    zIndex: 1000, // Ensure it stays on top

    // Media query for mobile screens
    "@media (max-width: 900px)": {
      top: "18vh", // Move down on mobile screens
    },
  },
  colorBox: {
    display: "inline-block",
    width: 20,
    height: 10,
    marginRight: 8,
    borderRadius: 2,
  },
  legendItem: {
    display: "flex",
    alignItems: "center",
    marginBottom: 8,
  },
  icon: {
    width: 20,
    height: 20,
    marginRight: 8,
  },
}));

const MapSettings = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  let { showSatellite, setShowSatellite } = useContext(MapContext);

  const GreenSwitch = styled(Switch)(({ theme }) => ({
    "& .MuiSwitch-switchBase.Mui-checked": {
      color: colors.greenAccent[600],
      "&:hover": {
        backgroundColor: alpha(
          colors.greenAccent[600],
          theme.palette.action.hoverOpacity
        ),
      },
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
      backgroundColor: colors.greenAccent[600],
    },
  }));

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "legend-popover" : undefined;

  return (
    <>
      {/* Floating Action Button */}
      <Fab
        color="primary"
        size="small"
        className={classes.fab}
        onClick={handleClick}
      >
        <SettingsIcon /> {/* Updated Icon to InfoIcon for better clarity */}
      </Fab>

      {/* Popover for the color legend */}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        PaperProps={{
          style: {
            maxHeight: "90vh", // Limit the height of the Popover
            overflowY: "auto", // Make content scrollable if necessary
          },
        }}
      >
        <Box padding="16px" width="250px">
          {/* Title for the legend */}
          <Typography variant="h4" style={{ margin: 0, marginBottom: 16 }}>
            Map Settings
          </Typography>{" "}
          <Divider />
          <MapConfiguration />
          <Divider />
        </Box>
      </Popover>
    </>
  );
};

export default MapSettings;
