import {
  Box,
  Checkbox,
  MenuItem,
  Select,
  Stack,
  Tab,
  Tabs,
  Typography,
  useTheme,
} from "@mui/material";
import PropTypes from "prop-types";
import { useContext, useEffect, useState } from "react";
import ReactGA from "react-ga4";
import AemoHIOFilter from "../../components/AemoHIOFilter";
import { GanttChart } from "../../components/gantt-chart/GanttChart";
import GanttChartLegend from "../../components/gantt-chart/GanttChartLegend";
import Refresh from "../../components/Refresh";
import Header from "../../components/section-headers/Header";
import SectionHeader from "../../components/section-headers/SectionHeader";
import AssetConstraintTable from "../../components/tables/AssetConstraintTable";
import AuthContext from "../../context/AuthContext";
import DataContext from "../../context/DataContext";
import { tokens } from "../../theme";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const riskLevels = {
  LOCAL_PRICE_RISK: 0,
  PARTIAL_CURTAILMENT: 1,
  FULL_CURTAILMENT: 2,
};

function a11yProps(index) {
  if (index === 0) {
    // window.location.replace("#gantt-chart");
  }
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const Portfolio = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  let { authTokens } = useContext(AuthContext);
  let {
    menu,
    refresh,
    startdate,
    enddate,
    aemoHIO,
    setAemoHIO,
    filedatetime,
    constraintsPortfolio,
    setConstraintsPortfolio,
    getNetworkConstraintsPortfolio,
    highImpactOutages,
    matchHighImpactOutages,
    getHighImpactOutages,
  } = useContext(DataContext);
  const [value, setValue] = useState(0);
  const [mixedConstraints, setMixedConstraints] = useState(0);
  const [selectedRisk, setSelectedRisk] = useState("LOCAL_PRICE_RISK");
  const [selectedStatuses, setSelectedStatuses] = useState([
    "SUBMIT",
    "RESUBMIT",
    "UTP",
    "MTLTP",
    "STLTP",
    "PDLTP",
    "PTP",
    "PTR",
  ]);
  const [genconids, setGenconids] = useState([]);
  const [filteredconstraints, setFilteredconstraints] = useState([]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const callHighImpactOutages = async () => {
    await getHighImpactOutages(authTokens);

    // setConstraints(_constraintSet);
  };

  useEffect(() => {
    if (constraintsPortfolio && highImpactOutages) {
      const mixedData = matchHighImpactOutages(
        constraintsPortfolio,
        highImpactOutages
      );
      setMixedConstraints(mixedData);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [constraintsPortfolio, highImpactOutages, aemoHIO]);

  const callPortfolioConstraintData = async () => {
    setConstraintsPortfolio([]);
    await getNetworkConstraintsPortfolio(startdate, enddate, filedatetime);

    // setConstraintsPortfolio(_constraintSet);
  };

  useEffect(() => {
    callHighImpactOutages(authTokens);
  }, []);

  useEffect(() => {
    if (mixedConstraints) {
      const filteredConstraints = mixedConstraints.filter(
        (constraint) =>
          riskLevels[constraint.risk] >= riskLevels[selectedRisk] &&
          (aemoHIO ? constraint.highimpact === "Yes" : true) &&
          (selectedStatuses.length === 0 ||
            selectedStatuses.includes(constraint.outagestatuscode))
      );
      setFilteredconstraints(filteredConstraints);

      const genconsetidSelection = [
        ...new Set(filteredConstraints.map((constr) => constr.genconsetid)),
      ];
      setGenconids(genconsetidSelection);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mixedConstraints, selectedRisk, selectedStatuses, aemoHIO]);

  const columns = [
    { field: "outageid", headerName: "OUTAGE ID", flex: 0.5 },
    {
      field: "genconsetid",
      headerName: "CONSTRAINT SET",
      flex: 1,
    },
    { field: "genconid", headerName: "CONSTRAINT", flex: 1.5 },
    {
      field: "description",
      headerName: "DESCRIPTION",
      flex: 0.75,
      hide: true,
    },
    { field: "duid", headerName: "DUID", flex: 1 },
    { field: "starttime", headerName: "START TIME", flex: 1 },
    { field: "endtime", headerName: "END TIME", flex: 1 },
    { field: "equipmentid", headerName: "ID", flex: 0.75 },
    { field: "equipmenttype", headerName: "TYPE", flex: 0.5 },
    {
      field: "substationid",
      headerName: "SUBSTATION ID",

      flex: 1,
    },
    {
      field: "outagestatuscode",
      headerName: "STATUS",
      flex: 0.5,
    },
    { field: "risk", headerName: "RISK", flex: 1 },
    { field: "bidtype", headerName: "BIDTYPE", flex: 0.75 },
    { field: "limittype", headerName: "LIMIT TYPE", flex: 1 },

    {
      field: "lastchanged",
      headerName: "LAST CHANGED",
      flex: 1,
    },
  ];

  useEffect(() => {
    if (constraintsPortfolio.length === 0) {
      callPortfolioConstraintData();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menu, filedatetime, enddate]);

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [constraintsPortfolio]);

  useEffect(() => {
    // Log the initial pageview
    ReactGA.send({ hitType: "dashboard", page: location.pathname });
  }, [location]);

  return (
    <Box m="5px">
      <Header
        title="Portfolio Overview"
        subtitle="Use the start and end dates to refresh data and view your portfolio of assets that are impacted by NOS Outages."
      />
      <Stack direction="row" spacing={2} mb={2}>
        <Box sx={{ display: "flex", gap: 2, alignItems: "center", mb: 2 }}>
          {/* AEMO HIO Filer */}
          <AemoHIOFilter
            aemoHIO={aemoHIO}
            setAemoHIO={setAemoHIO}
            colors={colors}
          />

          {/* Risk Filter */}
          <Box
            display="flex"
            alignItems="center"
            size="small"
            sx={{
              backgroundColor: colors.primary[400],
              borderRadius: "3px",
              padding: "0 0px ",
            }}
          >
            <Typography
              variant="subtitle1"
              fontWeight="bold"
              p="0px 10px 0px 10px"
              sx={{
                color: colors.grey[100],
              }}
            >
              Filter by Risk
            </Typography>
            <Select
              value={selectedRisk}
              onChange={(e) => setSelectedRisk(e.target.value)}
              size="small"
            >
              <MenuItem value="" disabled>
                Select Risk {">="} Level
              </MenuItem>
              {Object.keys(riskLevels).map((risk) => (
                <MenuItem key={risk} value={risk}>
                  {risk}
                </MenuItem>
              ))}
            </Select>
          </Box>

          {/* Status Filter */}
          <Box
            display="flex"
            alignItems="center"
            size="small"
            sx={{
              backgroundColor: colors.primary[400],
              borderRadius: "3px",
              padding: "0 0px ",
            }}
          >
            <Typography
              variant="subtitle1"
              fontWeight="bold"
              p="0px 10px 0px 10px"
              sx={{
                color: colors.grey[100],
              }}
            >
              Filter by Status
            </Typography>
            <Select
              multiple
              value={selectedStatuses}
              onChange={(e) => setSelectedStatuses(e.target.value)}
              renderValue={(selected) => selected.join(", ")}
              size="small"
            >
              {[
                "SUBMIT",
                "UTP",
                "MTLTP",
                "STLTP",
                "PDLTP",
                "PTP",
                "PTR",
                "RESUBMIT",
                "WD REQ",
                "WDRAWN",
                "INFO",
                "COMPLETE",
              ].map((status) => (
                <MenuItem key={status} value={status}>
                  <Checkbox
                    checked={selectedStatuses.indexOf(status) > -1}
                    style={{
                      color:
                        selectedStatuses.indexOf(status) > -1
                          ? colors.greenAccent[400]
                          : undefined,
                    }}
                  />
                  {status}
                </MenuItem>
              ))}
            </Select>
          </Box>
        </Box>
      </Stack>
      {(() => {
        if ((filteredconstraints.length > 0) & (refresh === false)) {
          return (
            <Box m="5px">
              <Box sx={{ width: "100%" }}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    textColor="secondary"
                    indicatorColor="secondary"
                    aria-label="secondary tabs example"
                  >
                    <Tab label="Gantt Chart" {...a11yProps(0)} />
                    {/* <Tab label="Tree View" {...a11yProps(1)} /> */}
                    <Tab label="Table View" {...a11yProps(1)} />
                  </Tabs>
                </Box>
                <CustomTabPanel value={value} index={0}>
                  {/* GANTT CHART VIEW */}
                  <SectionHeader
                    title="Gantt Chart"
                    subtitle={`View the Constraint Sets that will impact the assets within your Portfolio. Click on Tree or Table View for more details.`}
                  />
                  <Box id="gantt-chart">
                    <GanttChartLegend />
                    <GanttChart
                      key={`${aemoHIO}-${selectedRisk}-${filteredconstraints.length}`}
                      genconsetidSelection={genconids}
                      constraints={filteredconstraints}
                    />
                  </Box>
                </CustomTabPanel>
                {/* TREE VIEW TABLE */}
                {/* <CustomTabPanel value={value} index={1}>
                 
                  <SectionHeader
                    title="Tree Dropdown View"
                    subtitle={`Select Constraint Set and specific Constraint to View More Details for NOS Impact on the assets within your Portfolio`}
                  />
                  <ConstraintTree
                    genconsetidSelection={genconids}
                    constraints={filteredconstraints}
                  />
                </CustomTabPanel> */}

                <CustomTabPanel value={value} index={1}>
                  {/* TABLE VIEW */}
                  <SectionHeader
                    title="Table View"
                    subtitle={`Complete List of Constraint Sets & Ids that are likely to impact the assets within your Portfolio`}
                  />

                  <AssetConstraintTable constraints={filteredconstraints} />
                </CustomTabPanel>
              </Box>
            </Box>
          );
        } else if (refresh === true) {
          return <Refresh refresh={refresh} />;
        } else {
          return (
            <Typography variant="h3" color={colors.grey[100]}>
              No constraintsPortfolio found in Network Outage Schedule (NOS)
              that are linked to the assets within your Portfolio.
            </Typography>
          );
        }
      })()}
    </Box>
  );
};

export default Portfolio;
