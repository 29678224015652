import "esri-leaflet"; // Import Esri Leaflet
import L from "leaflet";
import icon from "leaflet/dist/images/marker-icon.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";
import "leaflet/dist/leaflet.css";
import { useEffect, useState } from "react";
import { GeoJSON, useMap } from "react-leaflet";

// Fix the default icon
L.Icon.Default.mergeOptions({
  iconUrl: icon,
  shadowUrl: iconShadow,
});

const GeoscienceAustraliaLines = ({ powerLines }) => {
  const map = useMap(); // Access the map instance
  const [filteredPowerLines, setFilteredPowerLines] = useState([]);

  useEffect(() => {
    // Function to filter power lines based on zoom level
    const filterPowerLinesByZoom = () => {
      const zoomLevel = map.getZoom();

      if (!powerLines || !powerLines.features) return;

      let filteredData;

      // Adjust filtering logic based on your desired zoom levels
      if (zoomLevel <= 5) {
        // Only show power lines with capacity greater than or equal to 275 kV
        filteredData = {
          ...powerLines,
          features: powerLines.features.filter(
            (feature) => feature.properties.capacity_kv >= 275
          ),
        };
      } else if (zoomLevel > 5 && zoomLevel <= 6) {
        // Show power lines with capacity greater than or equal to 220 kV
        filteredData = {
          ...powerLines,
          features: powerLines.features.filter(
            (feature) => feature.properties.capacity_kv >= 220
          ),
        };
      } else {
        // Show all power lines
        filteredData = powerLines;
      }

      setFilteredPowerLines(filteredData);
    };

    // Initial filtering
    filterPowerLinesByZoom();

    // Listen for zoom changes and update the filtering
    map.on("zoomend", filterPowerLinesByZoom);

    // Cleanup the event listener on component unmount
    return () => {
      if (map) {
        map.off("zoomend", filterPowerLinesByZoom);
      }
    };
  }, [map, powerLines]);

  // Function to style the lines
  const getLineStyle = (feature, zoom) => {
    const { capacity_kv } = feature.properties;
    const voltage = capacity_kv * 1000;
    let color;
    let weight;

    if (voltage >= 400000) {
      color = "#7326D3"; // "#B59F11";
      weight = 2;
    } else if (voltage >= 330000) {
      color = "#B54EB2"; // "#B59F11";
      weight = 2;
    } else if (voltage >= 275000) {
      color = "#C73030"; //"#F2AA2E";
      weight = 2;
    } else if (voltage >= 220000) {
      color = "#E55C00"; //"#E5007E";
      weight = 1.5;
    } else if (voltage >= 132000) {
      color = "#B55D00"; //"#0100FF";
      weight = 1.5;
    } else if (voltage >= 66000) {
      color = "#B59F11";
      weight = 1;
    } else if (voltage >= 33000) {
      color = "#56B555";
      weight = 1;
    } else if (voltage >= 22000) {
      color = "#6F97B8";
      weight = 1;
    } else {
      color = "#7A7A85";
      weight = 1;
    }

    return {
      color: color,
      weight: weight,
      opacity: 0.75,
    };
  };

  return (
    filteredPowerLines && (
      <GeoJSON
        key={JSON.stringify(filteredPowerLines)} // Force re-render when data changes
        data={filteredPowerLines}
        style={getLineStyle}
        onEachFeature={(feature, layer) => {
          const { name, capacity_kv } = feature.properties;
          const popupContent = `
            <div>
              <strong>Name:</strong> ${name} <br/>
              <strong>Capacity:</strong> ${capacity_kv} kV <br/>
            </div>
          `;
          layer.bindPopup(popupContent);
        }}
      />
    )
  );
};

export default GeoscienceAustraliaLines;
