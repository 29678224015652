import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { makeStyles } from "@mui/styles";

const ShuntCapacitor =
  process.env.PUBLIC_URL + "/assets/icons/power_capacitor_shunt.svg";
const Filter = process.env.PUBLIC_URL + "/assets/icons/power_filter.svg";
const SeriesReactor =
  process.env.PUBLIC_URL + "/assets/icons/power_reactor.svg";
const SeriesCapacitor =
  process.env.PUBLIC_URL + "/assets/icons/power_capacitor.svg";
const ShuntReactor =
  process.env.PUBLIC_URL + "/assets/icons/power_reactor_shunt.svg";
const CircuitBreaker =
  process.env.PUBLIC_URL + "/assets/icons/power_switch_circuit_breaker.svg";
const Disconnector =
  process.env.PUBLIC_URL + "/assets/icons/power_switch_disconnector.svg";
const Txfmr = process.env.PUBLIC_URL + "/assets/icons/power_transformer.svg";
const WindingTxfmr =
  process.env.PUBLIC_URL + "/assets/icons/power_transformer_3_winding.svg";
const CurrentTxfmr =
  process.env.PUBLIC_URL + "/assets/icons/power_transformer_current.svg";
const PotentialTxfmr =
  process.env.PUBLIC_URL + "/assets/icons/power_transformer_potential.svg";

// Custom styles for positioning the floating button and legend items
const useStyles = makeStyles(() => ({
  fab: {
    position: "fixed",
    top: "2vh",
    right: 20,
    zIndex: 1000, // Ensure it stays on top
  },
  colorBox: {
    display: "inline-block",
    width: 20,
    height: 10,
    marginRight: 8,
    borderRadius: 2,
  },
  legendItem: {
    display: "flex",
    alignItems: "center",
    marginBottom: 8,
  },
  icon: {
    width: 20,
    height: 20,
    marginRight: 8,
  },
}));

const EquipmentLegend = () => {
  const classes = useStyles();

  return (
    <>
      {/* Power Station Icons Section */}
      {/* Substation Equipment Icons Section */}
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          Substation Equipment
        </AccordionSummary>
        <AccordionDetails>
          <div className={classes.legendContainer}>
            {/* Transformers */}
            <div className={classes.legendItem}>
              <img
                className={classes.icon}
                src={Txfmr}
                alt="Power Transformer"
              />
              <span>Power Transformer</span>
            </div>
            <div className={classes.legendItem}>
              <img
                className={classes.icon}
                src={WindingTxfmr}
                alt="Three-Winding Transformer"
              />
              <span>Three-Winding Transformer</span>
            </div>
            <div className={classes.legendItem}>
              <img
                className={classes.icon}
                src={CurrentTxfmr}
                alt="Current Transformer"
              />
              <span>Current Transformer</span>
            </div>
            <div className={classes.legendItem}>
              <img
                className={classes.icon}
                src={PotentialTxfmr}
                alt="Potential Transformer"
              />
              <span>Potential Transformer</span>
            </div>

            {/* Switches */}
            <div className={classes.legendItem}>
              <img
                className={classes.icon}
                src={Disconnector}
                alt="Disconnector"
              />
              <span>Disconnector</span>
            </div>
            <div className={classes.legendItem}>
              <img
                className={classes.icon}
                src={CircuitBreaker}
                alt="Circuit Breaker"
              />
              <span>Circuit Breaker</span>
            </div>

            {/* Compensators */}
            <div className={classes.legendItem}>
              <img
                className={classes.icon}
                src={SeriesReactor}
                alt="Series Reactor"
              />
              <span>Series Reactor</span>
            </div>
            <div className={classes.legendItem}>
              <img
                className={classes.icon}
                src={ShuntReactor}
                alt="Shunt Reactor"
              />
              <span>Shunt Reactor</span>
            </div>
            <div className={classes.legendItem}>
              <img
                className={classes.icon}
                src={SeriesCapacitor}
                alt="Series Capacitor"
              />
              <span>Series Capacitor</span>
            </div>
            <div className={classes.legendItem}>
              <img
                className={classes.icon}
                src={ShuntCapacitor}
                alt="Shunt Capacitor"
              />
              <span>Shunt Capacitor</span>
            </div>
            <div className={classes.legendItem}>
              <img className={classes.icon} src={Filter} alt="Filter" />
              <span>Filter</span>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default EquipmentLegend;
