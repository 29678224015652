import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { makeStyles } from "@mui/styles";

const GeneratorIcon =
  process.env.PUBLIC_URL + "/assets/icons/power_generator.svg";

// Custom styles for positioning the floating button and legend items
const useStyles = makeStyles(() => ({
  fab: {
    position: "fixed",
    top: "2vh",
    right: 20,
    zIndex: 1000, // Ensure it stays on top
  },
  colorBox: {
    display: "inline-block",
    width: 20,
    height: 10,
    marginRight: 8,
    borderRadius: 2,
  },
  legendItem: {
    display: "flex",
    alignItems: "center",
    marginBottom: 8,
  },
  icon: {
    width: 20,
    height: 20,
    marginRight: 8,
  },
}));

const PowerStationLegend = () => {
  const classes = useStyles();

  return (
    <>
      {/* Power Station Icons Section */}
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          Power Stations
        </AccordionSummary>
        <AccordionDetails>
          <div className={classes.legendItem}>
            <img className={classes.icon} src={GeneratorIcon} alt="Other" />
            <span>Generator</span>
          </div>
          <div className={classes.legendItem}>
            <img
              className={classes.icon}
              src="https://cdn-icons-png.flaticon.com/512/3327/3327023.png"
              alt="Coal"
            />
            <span>Coal</span>
          </div>
          <div className={classes.legendItem}>
            <img
              className={classes.icon}
              src="https://cdn-icons-png.flaticon.com/512/2511/2511664.png"
              alt="Solar"
            />
            <span>Solar</span>
          </div>
          <div className={classes.legendItem}>
            <img
              className={classes.icon}
              src="https://cdn-icons-png.flaticon.com/512/9367/9367346.png"
              alt="Wind"
            />
            <span>Wind</span>
          </div>
          <div className={classes.legendItem}>
            <img
              className={classes.icon}
              src="https://cdn-icons-png.flaticon.com/512/3463/3463455.png"
              alt="Battery"
            />
            <span>Battery</span>
          </div>
          <div className={classes.legendItem}>
            <img
              className={classes.icon}
              src="https://cdn-icons-png.flaticon.com/512/4535/4535728.png"
              alt="Natural Gas"
            />
            <span>Natural Gas / Coal Seam Methane</span>
          </div>
          <div className={classes.legendItem}>
            <img
              className={classes.icon}
              src="https://cdn-icons-png.flaticon.com/512/1946/1946138.png"
              alt="Water"
            />
            <span>Water</span>
          </div>
          <div className={classes.legendItem}>
            <img
              className={classes.icon}
              src="https://cdn-icons-png.flaticon.com/512/4515/4515613.png"
              alt="Oil/Diesel"
            />
            <span>Oil/Diesel</span>
          </div>
          <div className={classes.legendItem}>
            <img
              className={classes.icon}
              src="https://cdn-icons-png.flaticon.com/512/5451/5451263.png"
              alt="Biogas"
            />
            <span>Biogas</span>
          </div>
          <div className={classes.legendItem}>
            <img
              className={classes.icon}
              src="https://cdn-icons-png.flaticon.com/512/11049/11049767.png"
              alt="Biomass"
            />
            <span>Biomass</span>
          </div>
          <div className={classes.legendItem}>
            <img
              className={classes.icon}
              src="https://cdn-icons-png.flaticon.com/512/3540/3540110.png"
              alt="Other"
            />
            <span>Other</span>
          </div>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default PowerStationLegend;
