import { Box, Switch, Typography } from "@mui/material";
import { alpha, styled } from "@mui/system";
import { tokens } from "../theme";

// Custom Styled Switch
const CustomSwitch = styled(Switch)(({ theme }) => {
  const colors = tokens(theme.palette.mode);
  return {
    "& .MuiSwitch-switchBase.Mui-checked": {
      color: colors.greenAccent[600],
      "&:hover": {
        backgroundColor: alpha(
          colors.greenAccent[600],
          theme.palette.action.hoverOpacity
        ),
      },
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
      backgroundColor: colors.greenAccent[600],
    },
  };
});

// **Reusable Toggle Switch Component**
const GreenSwitch = ({ label, checked, onChange, colors }) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      sx={{
        backgroundColor: colors.primary[400],
        borderRadius: "3px",
        padding: "0 0px",
      }}
    >
      <Typography
        variant="subtitle1"
        fontWeight="bold"
        sx={{ color: colors.grey[100], marginRight: "10px" }}
      >
        {label}
      </Typography>
      <CustomSwitch
        checked={checked}
        onChange={() => onChange((prev) => !prev)}
      />
    </Box>
  );
};

export default GreenSwitch;
