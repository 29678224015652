import "esri-leaflet"; // Import Esri Leaflet
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import { useContext, useEffect } from "react";
import { GeoJSON, useMap } from "react-leaflet";
import MapContext from "../../../../context/MapContext";

const GenIcon = process.env.PUBLIC_URL + "/assets/icons/power_generator.svg";

const Generators = ({ zoomLevel }) => {
  const map = useMap();
  const { generators } = useContext(MapContext);

  useEffect(() => {
    if (!generators || !generators.features || generators.features.length === 0)
      return;

    const updateTooltips = () => {
      const zoom = map.getZoom();

      // Define our thresholds
      const tooltipThreshold = 13; // At zoom >= 13, show ref/name
      const outputThreshold = 17; // At zoom >= 15, also add output

      generators?.features.forEach((feature) => {
        if (!feature._layer) return;
        const layer = feature._layer;
        // Determine the layer's latlng
        let latlng;
        if (typeof layer.getLatLng === "function") {
          latlng = layer.getLatLng();
        } else if (typeof layer.getBounds === "function") {
          latlng = layer.getBounds().getCenter();
        } else {
          return;
        }
        const isInBounds = map.getBounds().contains(latlng);
        let newContent = "";
        if (
          zoom >= tooltipThreshold &&
          isInBounds &&
          feature.geometry.type === "Point"
        ) {
          // Start with either ref or name (if they exist)
          let baseContent = "";

          // If zoom is high enough, add output information if available
          if (zoom >= outputThreshold) {
            // Try getting generator output or plant output (adjust as needed)
            const output =
              feature.properties["generator:output:electricity"] || "";
            if (output) {
              baseContent = `<br/><strong>${output}</strong>`;
            }
          }
          newContent = baseContent;
        }
        // Update the tooltip's content
        layer.setTooltipContent(newContent);
      });
    };

    map.on("zoomend", updateTooltips);
    updateTooltips(); // Run initially
    return () => {
      map.off("zoomend", updateTooltips);
    };
  }, [map, generators]);

  return (
    <>
      {generators && (
        <GeoJSON
          key={`${generators.length}-${zoomLevel}`} // Force re-render on zoomLevel change
          data={generators}
          pointToLayer={(feature, latlng) => {
            if (
              feature.geometry.type === "Point" &&
              feature.properties.markerOptions
            ) {
              if (zoomLevel >= 16) {
                const genIcon = new L.Icon({
                  iconUrl: GenIcon,
                  iconSize: [18, 18],
                  iconAnchor: [9, 9],
                  popupAnchor: [0, -18],
                });
                return L.marker(latlng, { icon: genIcon });
              } else {
                // Return a custom styled CircleMarker
                return L.circleMarker(latlng, feature.properties.markerOptions);
              }
            }
          }}
          style={(feature) => {
            if (feature.geometry.type === "Polygon") {
              return {
                color: "black", // Border color (outline)
                fillColor: "grey", // Fill color based on voltage
                weight: 2, // Border width
                fillOpacity: 0.4, // Transparency inside the polygon
              };
            }
            return {};
          }}
          onEachFeature={(feature, layer) => {
            const { id, name, operator, power, manufacturer, ref } =
              feature.properties;
            if (power === "plant") {
              const popupContent = `
              <div>
                <strong>Operator</strong> ${operator || ""} <br/>
                <strong>Name:</strong> ${name || ""} <br/>
                <strong>Fuel:</strong> ${
                  feature.properties["plant:source"] || ""
                } <br/>
                <strong>Rating:</strong> ${
                  feature.properties["plant:output:electricity"] || ""
                } <br/>
              </div>
            `;
              layer.bindPopup(popupContent);
            } else if (power === "generator") {
              const popupContent = `
              <div>
                <strong>Generator Type:</strong> ${
                  feature.properties["generator:method"] || ""
                } <br/>
                <strong>Generator Type:</strong> ${
                  feature.properties["generator:type"] || ""
                } <br/>
                <strong>Generator Source:</strong> ${
                  feature.properties["generator:source"] || ""
                }  <br/>
                <strong>Generator Rating:</strong> ${
                  feature.properties["generator:output:electricity"] || ""
                } <br/>
          
              </div>
            `;
              layer.bindPopup(popupContent);
            }

            // Add a click event listener to log properties when a line is clicked
            layer.on("click", () => {
              console.log("Clicked feature properties:", feature.properties);
            });
            // Define our thresholds
            const tooltipThreshold = 13; // At zoom >= 13, show ref/name
            const outputThreshold = 17; // At zoom >= 16, also add output

            let newContent = "";

            // Determine latlng depending on layer type
            let latlng;
            if (typeof layer.getLatLng === "function") {
              latlng = layer.getLatLng();
            } else if (typeof layer.getBounds === "function") {
              latlng = layer.getBounds().getCenter();
            } else {
              return;
            }
            const isInBounds = map.getBounds().contains(latlng);

            // Start with either ref or name (if they exist)
            let baseContent = "";

            // If zoom is high enough, add output information if available
            if (map.getZoom() >= outputThreshold) {
              // Try getting generator output or plant output (adjust as needed)
              const output =
                feature.properties["generator:output:electricity"] || "";
              if (output) {
                baseContent = `<br/><strong>${output}</strong>`;
              }
            }
            newContent = baseContent;

            // Initially check if the tooltip should be shown
            if (
              map.getZoom() >= tooltipThreshold &&
              isInBounds &&
              feature.geometry.type === "Point"
            ) {
              layer.bindTooltip(`<strong>${newContent}</strong>`, {
                permanent: true,
                direction: "bottom",
                offset: [0, 0],
                className: "generator-label",
              });
            }

            // Store layer reference in the feature
            feature._layer = layer;
          }}
        />
      )}
    </>
  );
};

export default Generators;
