import "esri-leaflet"; // Import Esri Leaflet
import L from "leaflet";
import icon from "leaflet/dist/images/marker-icon.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";
import "leaflet/dist/leaflet.css";
import { useContext, useEffect, useState } from "react";
import { MapContainer, TileLayer, useMapEvents } from "react-leaflet";
import MarkerCluserGroup from "react-leaflet-cluster";
import MapContext from "../../context/MapContext";
import Equipment from "./components/features/Equipment";
import Substations from "./components/features/Substations";
import MapLegend from "./components/MapLegend";
import MapSettings from "./components/MapSettings";
import GeoscienceAustraliaLines from "./components/powerlines/GeoscienceAustraliaLines";
import NetworkHV from "./components/powerlines/NetworkHV";
import NetworkLV from "./components/powerlines/NetworkLV";
import OSMTransmission from "./components/powerlines/OSMTransmission";
import SearchToZoom from "./components/SearchToZoom";
import Generators from "./components/stations/Generators";
import PowerStations from "./components/stations/PowerStations";
import ZoomedPowerStations from "./components/stations/ZoomedPowerStations";
import "./styles.css";

// Fix the default icon
L.Icon.Default.mergeOptions({
  iconUrl: icon,
  shadowUrl: iconShadow,
});

const transmissionLines =
  process.env.PUBLIC_URL + "/assets/electricity-transmission-lines.geojson";

export default function Map() {
  const [powerLines, setPowerLines] = useState(null);
  const [zoomLevel, setZoomLevel] = useState(0);
  let { showGALines, showSatellite, groupStations } = useContext(MapContext);

  const ZoomTracker = () => {
    useMapEvents({
      zoomend: (e) => {
        setZoomLevel(e.target.getZoom());
      },
    });
    return null;
  };

  useEffect(() => {
    if (typeof transmissionLines === "object") {
      setPowerLines(transmissionLines);
    } else {
      fetch(transmissionLines)
        .then((response) => response.json())
        .then((data) => setPowerLines(data));
    }
  }, []);

  return (
    <MapContainer
      center={[-30.5, 148.0]}
      zoom={5}
      maxZoom={21}
      preferCanvas={true}
    >
      <MapLegend />
      <MapSettings />

      <TileLayer
        // attribution="Sources: Esri © OpenStreetMap contributors, and the GIS User Community"
        maxZoom={21}
        attribution='&copy; <a href="https://www.stadiamaps.com/">Stadia Maps</a> &copy; <a href="https://openmaptiles.org/">OpenMapTiles</a> &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url={
          showSatellite
            ? "https://tiles.stadiamaps.com/tiles/alidade_satellite/{z}/{x}/{y}{r}.png"
            : "https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}{r}.png"
        }
      />
      <ZoomTracker />
      <SearchToZoom />
      {showGALines ? (
        <GeoscienceAustraliaLines powerLines={powerLines} />
      ) : (
        <OSMTransmission zoomLevel={zoomLevel} />
      )}
      <Substations />
      {zoomLevel >= 13 && <Generators zoomLevel={zoomLevel} />}
      {zoomLevel >= 15 && <Equipment zoomLevel={zoomLevel} />}
      {groupStations ? (
        <MarkerCluserGroup chunkedLoading disableClusteringAtZoom={8}>
          <PowerStations zoomLevel={zoomLevel} />
        </MarkerCluserGroup>
      ) : (
        <PowerStations zoomLevel={zoomLevel} />
      )}

      {/* {showGALines && <GeoscienceAustraliaLines powerLines={powerLines} />} */}
      {zoomLevel >= 16 && <NetworkHV zoomLevel={zoomLevel} />}
      {zoomLevel >= 18 && <NetworkLV zoomLevel={zoomLevel} />}
      {zoomLevel >= 10 && <ZoomedPowerStations zoomLevel={zoomLevel} />}
    </MapContainer>
  );
}
