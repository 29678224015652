import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  alpha,
  Divider,
  FormControlLabel,
  Switch,
  Typography,
} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { makeStyles, styled, useTheme } from "@mui/styles";
import { useContext } from "react";
import MapContext from "../../../../context/MapContext";
import { tokens } from "../../../../theme";

// Custom styles for positioning and legend items
const useStyles = makeStyles(() => ({
  switchContainer: {
    marginBottom: 12,
  },
}));

const MapConfiguration = () => {
  const classes = useStyles();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  let {
    showGALines,
    setShowGALines,
    showSatellite,
    setShowSatellite,
    groupStations,
    setGroupStations,
    NEMAssetsOnly,
    setNEMAssetsOnly,
  } = useContext(MapContext);

  const GreenSwitch = styled(Switch)(({ theme }) => ({
    "& .MuiSwitch-switchBase.Mui-checked": {
      color: colors.greenAccent[600],
      "&:hover": {
        backgroundColor: alpha(
          colors.greenAccent[600],
          theme.palette.action.hoverOpacity
        ),
      },
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
      backgroundColor: colors.greenAccent[600],
    },
  }));

  return (
    <Accordion defaultExpanded>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="h6">Configuration</Typography>
      </AccordionSummary>

      <AccordionDetails>
        {/* 📍 Map Layer Section */}
        <Typography variant="subtitle1" gutterBottom>
          Map Layers
        </Typography>
        <Divider sx={{ marginBottom: 1 }} />

        <div className={classes.switchContainer}>
          <FormControlLabel
            control={
              <GreenSwitch
                checked={showSatellite}
                onChange={(e) => setShowSatellite(e.target.checked)}
              />
            }
            label={<Typography variant="body1">Satellite Imagery</Typography>}
          />
        </div>

        {/* 🔌 Transmission Lines Section */}
        <Typography variant="subtitle1" gutterBottom>
          Transmission Lines
        </Typography>
        <Divider sx={{ marginBottom: 1 }} />

        <div className={classes.switchContainer}>
          <FormControlLabel
            control={
              <GreenSwitch
                checked={showGALines}
                onChange={(e) => setShowGALines(e.target.checked)}
              />
            }
            label={
              <Typography variant="body1">
                Geoscience Australia Lines (Nov-24)
              </Typography>
            }
          />
          <Typography variant="caption" style={{ fontStyle: "italic" }}>
            Defaults to OpenStreetMap Data
          </Typography>
        </div>

        {/* ⚡ Power Stations Section */}
        <Typography variant="subtitle1" gutterBottom>
          Power Stations
        </Typography>
        <Divider sx={{ marginBottom: 1 }} />

        <div className={classes.switchContainer}>
          <FormControlLabel
            control={
              <GreenSwitch
                checked={NEMAssetsOnly}
                onChange={(e) => setNEMAssetsOnly(e.target.checked)}
              />
            }
            label={
              <Typography variant="body1">Active NEM Assets Only</Typography>
            }
          />
        </div>
        <div className={classes.switchContainer}>
          <FormControlLabel
            control={
              <GreenSwitch
                checked={groupStations}
                onChange={(e) => setGroupStations(e.target.checked)}
              />
            }
            label={
              <Typography variant="body1">Cluster Power Stations</Typography>
            }
          />
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

export default MapConfiguration;
