import { Box } from "@mui/material";
import GreenSwitch from "./GreenSwitch";

const AemoHIOFilter = ({ aemoHIO, setAemoHIO, colors }) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      sx={{
        backgroundColor: colors.primary[400],
        borderRadius: "3px",
        padding: "0 10px",
      }}
    >
      <GreenSwitch
        key={`${aemoHIO}`}
        label={"AEMO High Impact Only"}
        checked={aemoHIO}
        onChange={() => setAemoHIO((prev) => !prev)}
        colors={colors}
      />
    </Box>
  );
};

export default AemoHIOFilter;
