import "esri-leaflet"; // Import Esri Leaflet
import "leaflet/dist/leaflet.css";
import { useRef } from "react";
import { FeatureLayer } from "react-esri-leaflet";
import { useMap } from "react-leaflet";

const NetworkLV = ({ zoomLevel }) => {
  const map = useMap();
  const zoom = map.getZoom();
  const featureLayerRef = useRef();

  // Function to style the lines
  const getLineStyle = (feature) => {
    const { OPERATING_VOLTAGE } = feature.properties;
    if (OPERATING_VOLTAGE) {
      const strippedVoltage = OPERATING_VOLTAGE.replace(/\s*kV$/i, ""); // Remove "kV" with optional space
      const capacity_kv = parseInt(strippedVoltage, 10); // Convert to number
      let color;
      let weight;

      if (capacity_kv <= 66) {
        color = "#77410F";
        weight = 2;
      } else if (capacity_kv <= 132) {
        color = "#E4012D";
        weight = 2.5;
      } else if (capacity_kv <= 220) {
        color = "#0100FF";
        weight = 3;
      } else if (capacity_kv <= 275) {
        color = "#E5007E";
        weight = 3.5;
      } else if (capacity_kv <= 330) {
        color = "#F2AA2E";
        weight = 4;
      } else {
        color = "#FFED00";
        weight = 4.5;
      }

      return {
        color: "#7A7A85",
        weight: 2,
        opacity: 0.3,
      };
    } else {
      return {
        color: "#7A7A85",
        weight: 2,
        opacity: 0.3,
      };
    }
  };

  return (
    <FeatureLayer
      ref={featureLayerRef}
      url="https://services-ap1.arcgis.com/ug6sGLFkytbXYo4f/arcgis/rest/services/LUAL_Network_LV_Feature_Public/FeatureServer/0"
      simplifyFactor={1}
      style={getLineStyle}
      onEachFeature={(feature, layer) => {
        const { OPERATING_VOLTAGE, OWNER, OBJECTID } = feature.properties;

        const popupContent = `
            <div>
              <strong>OPERATING_VOLTAGE:</strong> ${OPERATING_VOLTAGE} <br/>
              <strong>OWNER:</strong> ${OWNER} <br/>
              <strong>OBJECTID:</strong> ${OBJECTID || "N/A"} <br/>
            </div>
          `;
        layer.bindPopup(popupContent);

        // Add a click event listener to log properties when a line is clicked
        layer.on("click", () => {
          console.log("Clicked feature properties:", feature.properties);
        });
      }}
      eventHandlers={
        {
          // loading: () => {
          //   console.log("featurelayer loading");
          // },
          // load: () => {
          //   console.log("featurelayer loaded");
          // },
        }
      }
    />
  );
};

export default NetworkLV;
