import "esri-leaflet"; // Import Esri Leaflet
import "leaflet/dist/leaflet.css";
import { useRef } from "react";
import { FeatureLayer } from "react-esri-leaflet";
import { useMap } from "react-leaflet";

const NetworkHV = ({ zoomLevel }) => {
  const map = useMap();
  const zoom = map.getZoom();
  const featureLayerRef = useRef();
  const labelLayersRef = useRef([]); // Store each feature layer reference here
  const labelMarkersRef = useRef([]); // Store label markers here

  // Function to style the lines
  const getLineStyle = (feature) => {
    const { OPERATING_VOLTAGE, ASSET_TYPE } = feature.properties;
    if (OPERATING_VOLTAGE) {
      const strippedVoltage = OPERATING_VOLTAGE.replace(/\s*kV$/i, ""); // Remove "kV" with optional space
      const capacity_kv = parseInt(strippedVoltage, 10); // Convert to number
      let color;
      let weight;

      if (capacity_kv <= 66) {
        color = "#77410F";
        weight = 1;
      } else if (capacity_kv <= 132) {
        color = "#E4012D";
        weight = 1.5;
      } else if (capacity_kv <= 220) {
        color = "#0100FF";
        weight = 2;
      } else if (capacity_kv <= 275) {
        color = "#E5007E";
        weight = 2.5;
      } else if (capacity_kv <= 330) {
        color = "#F2AA2E";
        weight = 3;
      } else {
        color = "#FFED00";
        weight = 3;
      }

      if (ASSET_TYPE === "OH") {
        return {
          color: "#BFBC6B",
          weight: 2,
          opacity: 0.75,
        };
      } else if (ASSET_TYPE === "UG") {
        return {
          color: "#BFBC6B",
          weight: 2,
          opacity: 0.75,
          dashArray: "10, 5",
        };
      } else {
        return {
          color: "#BFBC6B",
          weight: 2,
          opacity: 0.75,
          dashArray: "2, 5",
        };
      }
    } else {
      return {
        color: "#7A7A85",
        weight: 2,
        opacity: 0.3,
      };
    }
  };

  // Function to add labels with outlined text to lines at intervals
  const addVoltageLabels = (layer, feature) => {
    if (!feature.properties || !feature.properties.OPERATING_VOLTAGE) return; // Skip if properties or OPERATING_VOLTAGE is missing

    const { OPERATING_VOLTAGE } = feature.properties;
    const lineColor = getLineStyle(feature).color;

    // Only add labels if the map zoom level is 17 or higher
    if (map.getZoom() >= 18 && OPERATING_VOLTAGE) {
      const latLngs = layer.getLatLngs();
      if (latLngs.length < 2) return; // Skip if the line has less than two points

      const labelInterval = 3; // Adjust this interval as needed

      for (let i = 0; i < latLngs.length - 1; i += labelInterval) {
        const startPoint = latLngs[i];
        const endPoint = latLngs[i + 1];
        const midPoint = L.latLng(
          (startPoint.lat + endPoint.lat) / 2,
          (startPoint.lng + endPoint.lng) / 2
        );

        // Calculate the angle of the segment in degrees
        const angleRad = Math.atan2(
          endPoint.lat - startPoint.lat,
          endPoint.lng - startPoint.lng
        );
        const angleDeg = (angleRad * 180) / Math.PI;

        const labelIcon = L.divIcon({
          className: "voltage-label",
          html: `<div style="
            color: white;
            font-weight: bold;
            font-size: 14px;
            white-space: nowrap;
            transform: rotate(${-angleDeg}deg);
            transform-origin: center;
            text-shadow: 
              -1px -1px 0 ${lineColor}, 
               1px -1px 0 ${lineColor}, 
              -1px  1px 0 ${lineColor}, 
               1px  1px 0 ${lineColor};
          ">${OPERATING_VOLTAGE}</div>`,
          iconSize: [100, 20],
        });

        const labelMarker = L.marker(midPoint, {
          icon: labelIcon,
          interactive: false,
        }).addTo(map);
        labelMarkersRef.current.push(labelMarker);
      }
    }
  };

  // Remove all label markers from the map
  const removeAllLabels = () => {
    labelMarkersRef.current.forEach((labelMarker) => {
      map.removeLayer(labelMarker);
    });
    labelMarkersRef.current = [];
  };

  // Add or remove labels based on zoom level
  const updateLabelsBasedOnZoom = () => {
    const currentZoom = map.getZoom();
    if (currentZoom >= 18) {
      // Apply labels to each stored layer if zoom level >= 17
      labelLayersRef.current.forEach((layerObj) => {
        addVoltageLabels(layerObj.layer, layerObj.feature);
      });
    } else {
      removeAllLabels(); // Remove labels at lower zoom levels
    }
  };

  // Listen for zoom changes to dynamically add or remove labels
  map.on("zoomend", updateLabelsBasedOnZoom);

  return (
    <FeatureLayer
      ref={featureLayerRef}
      url="https://services-ap1.arcgis.com/ug6sGLFkytbXYo4f/arcgis/rest/services/LUAL_Network_HV_Feature_Public/FeatureServer/0"
      simplifyFactor={1}
      style={getLineStyle}
      onEachFeature={(feature, layer) => {
        const { OPERATING_VOLTAGE, OWNER, OBJECTID } = feature.properties;

        const popupContent = `
            <div>
              <strong>OPERATING_VOLTAGE:</strong> ${OPERATING_VOLTAGE} <br/>
              <strong>OWNER:</strong> ${OWNER} <br/>
              <strong>OBJECTID:</strong> ${OBJECTID || "N/A"} <br/>
            </div>
          `;
        layer.bindPopup(popupContent);

        // Store each feature layer reference in labelLayersRef
        // labelLayersRef.current.push({ layer, feature });

        // Add a click event listener to log properties when a line is clicked
        layer.on("click", () => {
          console.log("Clicked feature properties:", feature.properties);
        });

        // Initially add labels if the zoom level is already >= 17
        // if (map.getZoom() >= 18) {  addVoltageLabels(layer, feature); }
      }}
      eventHandlers={
        {
          // loading: () => {
          //   console.log("featurelayer loading");
          // },
          // load: () => {
          //   console.log("featurelayer loaded");
          // },
        }
      }
    />
  );
};

export default NetworkHV;
