import "esri-leaflet"; // Import Esri Leaflet
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import { useContext, useEffect } from "react";
import { GeoJSON, useMap } from "react-leaflet";
import MapContext from "../../../../context/MapContext";

const ShuntCapacitor =
  process.env.PUBLIC_URL + "/assets/icons/power_capacitor_shunt.svg";
const Filter = process.env.PUBLIC_URL + "/assets/icons/power_filter.svg";
const SeriesReactor =
  process.env.PUBLIC_URL + "/assets/icons/power_reactor.svg";
const SeriesCapacitor =
  process.env.PUBLIC_URL + "/assets/icons/power_capacitor.svg";
const ShuntReactor =
  process.env.PUBLIC_URL + "/assets/icons/power_reactor_shunt.svg";
const CircuitBreaker =
  process.env.PUBLIC_URL + "/assets/icons/power_switch_circuit_breaker.svg";
const Disconnector =
  process.env.PUBLIC_URL + "/assets/icons/power_switch_disconnector.svg";
const Txfmr = process.env.PUBLIC_URL + "/assets/icons/power_transformer.svg";
const WindingTxfmr =
  process.env.PUBLIC_URL + "/assets/icons/power_transformer_3_winding.svg";
const CurrentTxfmr =
  process.env.PUBLIC_URL + "/assets/icons/power_transformer_current.svg";
const PotentialTxfmr =
  process.env.PUBLIC_URL + "/assets/icons/power_transformer_potential.svg";

function getVoltageLabel(subType) {
  // Extract and convert voltage values if they exist
  const voltages = ["voltage:primary", "voltage:secondary", "voltage:tertiary"]
    .map((key) => subType[key])
    .filter((value) => !isNaN(value)) // Ensure it's a valid number
    .map((value) => Number(value) / 1000) // Convert and divide by 1000
    .sort((a, b) => b - a); // Sort highest to lowest

  // Create label based on available values
  const label = voltages.length ? voltages.join("/") + " kV" : "N/A";

  return label;
}

// Icon mapping based on powerType and subType
const getIcon = (powerType, subType) => {
  const tertiaryVoltage = subType["voltage:tertiary"] || "N/A";
  switch (powerType) {
    case "switch":
      switch (subType.switch) {
        case "disconnector":
          return Disconnector;
        case "circuit_breaker":
          return CircuitBreaker;
        default:
          return Disconnector;
      }

    case "compensator":
      switch (subType.compensator) {
        case "series_reactor":
          return SeriesReactor;
        case "shunt_reactor":
          return ShuntReactor;
        case "series_capacitor":
          return SeriesCapacitor;
        case "shunt_capacitor":
          return ShuntCapacitor;
        case "filter":
          return Filter;
        default:
          return SeriesReactor;
      }

    case "transformer":
      switch (subType.type) {
        case "current":
          return CurrentTxfmr;
        case "potential":
          return PotentialTxfmr;
        case "tertiary_voltage":
          return WindingTxfmr;
        default:
          return tertiaryVoltage !== "N/A" ? WindingTxfmr : Txfmr;
      }

    default:
      return Txfmr; // Fallback for unknown power types
  }
};

const Equipment = ({ zoomLevel }) => {
  const map = useMap();
  const { substationEquipment } = useContext(MapContext);

  useEffect(() => {
    if (
      !substationEquipment ||
      !substationEquipment.features ||
      substationEquipment.features.length === 0
    )
      return;

    const updateTooltips = () => {
      const zoom = map.getZoom();

      // Define our thresholds
      const tooltipThreshold = 13; // At zoom >= 13, show ref/name
      const outputThreshold = 18; // At zoom >= 15, also add output

      substationEquipment?.features.forEach((feature) => {
        if (!feature._layer) return;
        const layer = feature._layer;
        // Determine the layer's latlng
        let latlng;
        if (typeof layer.getLatLng === "function") {
          latlng = layer.getLatLng();
        } else if (typeof layer.getBounds === "function") {
          latlng = layer.getBounds().getCenter();
        } else {
          return;
        }
        const isTxfmr = feature.properties.power === "transformer";
        const isInBounds = map.getBounds().contains(latlng);
        let newContent = "";
        if (
          zoom >= tooltipThreshold &&
          isInBounds &&
          isTxfmr &&
          feature.geometry.type === "Point"
        ) {
          // Start with either ref or name (if they exist)
          let baseContent = "";

          // If zoom is high enough, add output information if available
          if (zoom >= outputThreshold) {
            // Try getting generator output or plant output (adjust as needed)
            const output = getVoltageLabel(feature.properties);
            if (output) {
              baseContent = `<br/><strong>${output}</strong>`;
            }
          }
          newContent = baseContent;
        }
        // Update the tooltip's content
        layer.setTooltipContent(newContent);
      });
    };

    map.on("zoomend", updateTooltips);
    updateTooltips(); // Run initially
    return () => {
      map.off("zoomend", updateTooltips);
    };
  }, [map, substationEquipment]);

  return (
    <>
      {substationEquipment && (
        <GeoJSON
          key={`${substationEquipment.length}-${zoomLevel}`} // Force re-render on zoomLevel change
          data={substationEquipment}
          pointToLayer={(feature, latlng) => {
            const isInBounds = map.getBounds().contains(latlng);

            let marker;

            if (
              feature.geometry.type === "Point" &&
              feature.properties.markerOptions &&
              isInBounds
            ) {
              if (zoomLevel >= 17) {
                const genIcon = new L.Icon({
                  iconUrl: getIcon(
                    feature.properties.power,
                    feature.properties
                  ),
                  iconSize: [20, 20],
                  iconAnchor: [10, 10],
                  popupAnchor: [0, -20],
                });

                marker = L.marker(latlng, { icon: genIcon });
              } else {
                marker = L.circleMarker(
                  latlng,
                  feature.properties.markerOptions
                );
              }

              return marker;
            }
          }}
          style={(feature) => {
            if (feature.geometry.type === "Polygon") {
              return {
                color: "black", // Border color (outline)
                fillColor: "grey", // Fill color based on voltage
                weight: 2, // Border width
                fillOpacity: 0.4, // Transparency inside the polygon
              };
            }
            return {};
          }}
          onEachFeature={(feature, layer) => {
            const power = feature.properties.power;
            let popupContent = "";

            if (power === "transformer") {
              popupContent = `
      <div>
        <strong>Transformer</strong> <br/>
        <strong>Phases:</strong> ${feature.properties.phases || "N/A"} <br/>
        <strong>Rating:</strong> ${feature.properties.rating || "N/A"} <br/>
        <strong>Primary Voltage:</strong> ${
          feature.properties["voltage:primary"] || "N/A"
        } <br/>
        <strong>Secondary Voltage:</strong> ${
          feature.properties["voltage:secondary"] || "N/A"
        } <br/>
        <strong>Tertiary Voltage:</strong> ${
          feature.properties["voltage:tertiary"] || "N/A"
        } <br/>
      </div>
    `;
            } else if (power === "compensator") {
              popupContent = `
      <div>
        <strong>Compensator</strong> <br/>
        <strong>Type:</strong> ${feature.properties.compensator || "N/A"} <br/>
        <strong>Voltage:</strong> ${feature.properties.voltage || "N/A"} <br/>
      </div>
    `;
            } else if (power === "switch") {
              popupContent = `
      <div>
        <strong>Switch</strong> <br/>
        <strong>Type:</strong> ${feature.properties.switch || "N/A"} <br/>
        <strong>Voltage:</strong> ${feature.properties.voltage || "N/A"} <br/>
      </div>
    `;
            }

            if (popupContent) {
              layer.bindPopup(popupContent);
            }
            layer.on("click", () => {
              console.log("Clicked feature properties:", feature.properties);
            });
            // Define our thresholds
            const tooltipThreshold = 13; // At zoom >= 13, show ref/name
            const outputThreshold = 18; // At zoom >= 16, also add output

            let newContent = "";

            // Determine latlng depending on layer type
            let latlng;
            if (typeof layer.getLatLng === "function") {
              latlng = layer.getLatLng();
            } else if (typeof layer.getBounds === "function") {
              latlng = layer.getBounds().getCenter();
            } else {
              return;
            }
            const isInBounds = map.getBounds().contains(latlng);
            const isTxfmr = feature.properties.power === "transformer";

            // Start with either ref or name (if they exist)
            let baseContent = "";

            if (map.getZoom() >= outputThreshold && isInBounds && isTxfmr) {
              // Try getting generator output or plant output (adjust as needed)
              const output = getVoltageLabel(feature.properties);
              if (output) {
                baseContent = `<br/><strong>${output}</strong>`;
              }
            }
            newContent = baseContent;

            // Initially check if the tooltip should be shown
            if (
              map.getZoom() >= tooltipThreshold &&
              isInBounds &&
              isTxfmr &&
              feature.geometry.type === "Point"
            ) {
              layer.bindTooltip(`<strong>${newContent}</strong>`, {
                permanent: true,
                direction: "bottom",
                offset: [0, 0],
                className: "generator-label",
              });
            }

            // Store layer reference in the feature
            feature._layer = layer;
          }}
        />
      )}
    </>
  );
};

export default Equipment;
