import * as turf from "@turf/convex";
import { parse, stringify } from "flatted";
import { useSnackbar } from "notistack";
import { createContext, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const MapContext = createContext();

export default MapContext;

const fetchWithRetry = async (query, retries = 5, delay = 5000) => {
  for (let i = 0; i < retries; i++) {
    try {
      const response = await fetch("https://overpass-api.de/api/interpreter", {
        method: "POST",
        body: new URLSearchParams({ data: query }),
      });
      if (!response.ok)
        throw new Error(`HTTP error! Status: ${response.status}`);
      return await response.json();
    } catch (error) {
      console.warn(`Attempt ${i + 1} failed: ${error.message}`);
      if (i < retries - 1) await new Promise((res) => setTimeout(res, delay));
    }
  }
  throw new Error(
    "Max retries reached. Overpass API may be down or rate-limiting."
  );
};

const calculateHull = (points) => {
  if (points.length < 3) {
    // Convex hull requires at least 3 points
    return null;
  }

  // Create a GeoJSON FeatureCollection of the points
  const pointsGeoJSON = {
    type: "FeatureCollection",
    features: points.map(([lon, lat]) => ({
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [lon, lat],
      },
    })),
  };

  // Calculate the convex hull using @turf/convex
  const hull = turf.convex(pointsGeoJSON);

  return hull || null;
};

const getStoredValue = (key, defaultValue) => {
  const storedValue = localStorage.getItem(key);

  if (
    storedValue !== null &&
    storedValue !== "null" &&
    storedValue !== "undefined"
  ) {
    try {
      return parse(storedValue);
    } catch (error) {
      console.warn(`Error parsing localStorage key "${key}":`, error);
    }
  }
  return defaultValue;
};

const getMidpointFromBounds = (bounds) => {
  // Destructure min and max latitudes and longitudes from the bounds
  const { minlat, minlon, maxlat, maxlon } = bounds;

  // Calculate the midpoint by averaging the latitudes and longitudes
  const latMidpoint = (minlat + maxlat) / 2;
  const lonMidpoint = (minlon + maxlon) / 2;

  // Return the midpoint as an object or LatLng if using Leaflet
  return [lonMidpoint, latMidpoint];
};

const getSubstationColor = (capacity_v) => {
  const capacity_kv = capacity_v / 1000;
  if (capacity_kv <= 66) {
    return { color: "#77410F", weight: 1 };
  } else if (capacity_kv <= 132) {
    return { color: "#E4012D", weight: 1.5 };
  } else if (capacity_kv <= 220) {
    return { color: "#0100FF", weight: 2 };
  } else if (capacity_kv <= 275) {
    return { color: "#E5007E", weight: 2.5 };
  } else if (capacity_kv <= 330) {
    return { color: "#F2AA2E", weight: 3 };
  } else {
    return { color: "#B59F11", weight: 3.5 };
  }
};

const openDatabase = (storageName) => {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open("MapDatabase", 1);

    // Error handling
    request.onerror = reject;

    // Success callback
    request.onsuccess = () => resolve(request.result);

    // Upgrade logic (create object store if it doesn't exist)
    request.onupgradeneeded = (e) => {
      const db = e.target.result;

      // Create the NetworkData object store if it doesn't exist
      if (!db.objectStoreNames.contains("NetworkData")) {
        db.createObjectStore("NetworkData", { keyPath: "id" });
      }

      // Create object store for storageName if not present
      if (!db.objectStoreNames.contains(storageName)) {
        db.createObjectStore(storageName, { keyPath: "id" });
      }
    };
  });
};

// Function to fetch data from IndexedDB
const fetchDataFromIndexedDB = async (storageName) => {
  const db = await openDatabase("NetworkData");
  return new Promise((resolve, reject) => {
    const transaction = db.transaction("NetworkData", "readonly");
    const store = transaction.objectStore("NetworkData");

    const request = store.get(storageName);

    // Error handling
    request.onerror = (event) => {
      console.error("Error reading from IndexedDB:", event);
      reject(event);
    };

    // Success callback
    request.onsuccess = (event) => {
      const result = event.target.result;
      resolve(result ? result.data : []); // Return empty array if no data found
    };
  });
};

const storeDataInIndexedDB = async (storageName, data) => {
  const db = await openDatabase("NetworkData");
  const transaction = db.transaction("NetworkData", "readwrite");
  const store = transaction.objectStore("NetworkData");

  // Put data into the store under the specified storageName
  store.put({ id: storageName, data: data });

  // You can also add an error handler if needed
  transaction.onerror = (event) => {
    console.error("Error storing data in IndexedDB:", event);
  };

  // Commit the transaction
  transaction.oncomplete = () => {
    console.log(`${storageName} data successfully saved to IndexedDB.`);
  };
};

export const MapProvider = ({ children }) => {
  const [showSatellite, setShowSatellite] = useState(false);
  const [showGALines, setShowGALines] = useState(() => {
    const storedData = localStorage.getItem("showGALines");
    return storedData ? JSON.parse(storedData) : true;
  });
  const [groupStations, setGroupStations] = useState(true);
  const [NEMAssetsOnly, setNEMAssetsOnly] = useState(false);
  const [DUIDDetails, setDUIDDetails] = useState(() =>
    getStoredValue("DUIDDetails", [])
  );
  const [powerStationsBoundaries, setPowerStationsBoundaries] = useState(null);
  const [zoomedPowerStations, setZoomedPowerStations] = useState(null);
  const [OSMTransmissionLines, setOSMTransmissionLines] = useState([]);
  const [substationEquipment, setSubstationEquipment] = useState();
  const [generators, setGenerators] = useState([]);
  const [stationToOSMMap, setStationToOSMMap] = useState(() =>
    getStoredValue("stationToOSMMap", [])
  );
  const [powerStations, setPowerStations] = useState(null);
  const [substations, setSubstations] = useState([]);

  useEffect(() => {
    localStorage.setItem("stationToOSMMap", stringify(stationToOSMMap));
  }, [stationToOSMMap]);

  useEffect(() => {
    localStorage.setItem("DUIDDetails", stringify(DUIDDetails));
  }, [DUIDDetails]);

  // Effect to retrieve data from IndexedDB when component mounts
  useEffect(() => {
    const loadData = async () => {
      const lineData = await fetchDataFromIndexedDB("OSMTransmissionLines");
      const substationData = await fetchDataFromIndexedDB("substations");
      const powerStationsData = await fetchDataFromIndexedDB("powerStations");
      const powerStationsBoundariesData = await fetchDataFromIndexedDB(
        "powerStationsBoundaries"
      );
      const zoomedPowerStationsData = await fetchDataFromIndexedDB(
        "zoomedPowerStations"
      );
      const generatorsData = await fetchDataFromIndexedDB("generators");
      const substationEquipmentData = await fetchDataFromIndexedDB(
        "substationEquipment"
      );
      setOSMTransmissionLines(lineData); // Set state with data retrieved from IndexedDB
      setSubstations(substationData);
      if (powerStationsData !== "undefined") {
        setPowerStations(powerStationsData);
      }
      setPowerStationsBoundaries(powerStationsBoundariesData);
      setGenerators(generatorsData);
      setSubstationEquipment(substationEquipmentData);
      setZoomedPowerStations(zoomedPowerStationsData);
    };

    loadData();
  }, []); // Empty dependency array ensures this runs once when the component mounts

  // Effect to store data in IndexedDB when OSMTransmissionLines state changes
  useEffect(() => {
    if (OSMTransmissionLines.length > 0) {
      storeDataInIndexedDB("OSMTransmissionLines", OSMTransmissionLines); // Store data in IndexedDB whenever the state changes
    }
  }, [OSMTransmissionLines]); // Runs whenever OSMTransmissionLines changes

  useEffect(() => {
    if (substations.features?.length > 0) {
      storeDataInIndexedDB("substations", substations); // Store data in IndexedDB whenever the state changes
    }
  }, [substations]); // Runs whenever OSMTransmissionLines changes

  useEffect(() => {
    if (generators.features?.length > 0) {
      storeDataInIndexedDB("generators", generators); // Store data in IndexedDB whenever the state changes
    }
  }, [generators]); // Runs whenever OSMTransmissionLines changes

  useEffect(() => {
    if (substationEquipment?.features?.length > 0) {
      storeDataInIndexedDB("substationEquipment", substationEquipment); // Store data in IndexedDB whenever the state changes
    }
  }, [substationEquipment]); // Runs whenever OSMTransmissionLines changes

  useEffect(() => {
    localStorage.setItem("showGALines", JSON.stringify(showGALines));
  }, [showGALines]);

  const { enqueueSnackbar } = useSnackbar();

  const navigate = useNavigate();

  const fetchPowerPlants = useCallback(async () => {
    try {
      const normalOverpassQuery = `
          [out:json][timeout:50000];
          area[name="Australia"]->.a;
          (
            node["power"="plant"](area.a);
            node["construction:power"="plant"](area.a);
            way["power"="plant"](area.a);
            way["construction:power"="plant"](area.a);
            relation["power"="plant"](area.a);
            relation["construction:power"="plant"](area.a);
          );
          out tags center;
        `;

      // Fetch sequentially
      const normalData = await fetchWithRetry(normalOverpassQuery);

      const geoJsonData = {
        type: "FeatureCollection",
        features: processPowerStations(normalData.elements),
      };
      // Deep clone the object
      const clone = JSON.parse(JSON.stringify(geoJsonData));
      setPowerStations(geoJsonData);
      storeDataInIndexedDB("powerStations", clone);
    } catch (error) {
      console.error("Failed to fetch power plants:", error);
    }
  }, []);

  const fetchPowerPlantsBoundaries = useCallback(async () => {
    try {
      const outerOverpassQuery = `
          [out:json][timeout:50000];
          area[name="Australia"]->.a;
          (
            relation["power"="plant"](area.a);
            relation["construction:power"="plant"](area.a);
          )->.plants;
          (
            .plants->.outer_ways;
            way(r.outer_ways)["role"="outer"];
            node(w.outer_ways);
          );
          out body geom;
        `;
      // Fetch sequentially
      const outerData = await fetchWithRetry(outerOverpassQuery);

      const geoJsonData = {
        type: "FeatureCollection",
        features: processPowerStationsBoundaries(outerData.elements),
      };

      // Deep clone the object
      const clone = JSON.parse(JSON.stringify(geoJsonData));
      setPowerStationsBoundaries(geoJsonData);
      storeDataInIndexedDB("powerStationsBoundaries", clone);
    } catch (error) {
      console.error("Failed to fetch power plants:", error);
    }
  }, []);

  const getPlantStatus = (features) => {
    if (features.tags?.["power"] === "plant") {
      return "Operating";
    } else if (features.tags?.["construction:power"] === "plant") {
      return "Under Construction";
    } else {
      return "Unknown/Retired";
    }
  };

  const processPowerStations = (normalElements) => {
    const processedStations = [];

    // add normal power stations
    normalElements.forEach((element) => {
      if (element.type === "node") {
        processedStations.push({
          type: "Feature",
          properties: {
            id: element.id,
            ...element.tags,
            plantOutput: element.tags?.["plant:output:electricity"] || "",
            plantSource: element.tags?.["plant:source"] || "",
            plantStatus: getPlantStatus(element),
          },
          geometry: { type: "Point", coordinates: [element.lon, element.lat] },
        });
      } else if (element.type === "way" || element.type === "relation") {
        if (element.center) {
          processedStations.push({
            type: "Feature",
            properties: {
              id: element.id,
              ...element.tags,
              plantOutput: element.tags?.["plant:output:electricity"] || "",
              plantSource: element.tags?.["plant:source"] || "",
              plantStatus: getPlantStatus(element),
            },
            geometry: {
              type: "Point",
              coordinates: [element.center.lon, element.center.lat],
            },
          });
        }
      }
    });

    return processedStations;
  };

  const processPowerStationsBoundaries = (outerElements) => {
    const processedStations = [];

    // Add the polygon feature if hull exists for outerElements
    outerElements.forEach((relation) => {
      const points = [];

      // Extract points from members
      relation.members.forEach((member) => {
        if (member.type === "node") {
          if (member.lon !== NaN && member.lat !== NaN) {
            points.push([member.lon, member.lat]);
          }
        } else if (member.type === "way") {
          // Calculate the centroid for ways and relations
          if (member.geometry) {
            member.geometry.forEach((node) => {
              if (node.lon !== NaN && node.lat !== NaN) {
                points.push([node.lon, node.lat]);
              }
            });
          }
        }
      });

      const hull = calculateHull(points);
      // Check if the name already exists in the list
      const alreadyExists = processedStations.some((station) => {
        return station.properties.name === relation.tags.name;
      });

      // Find the index of the object with the same name
      const index = processedStations.findIndex(
        (station) => station.properties.name === relation.tags.name
      );

      if (hull && !alreadyExists) {
        const newPowerStationFeature = {
          type: "Feature",
          properties: {
            id: relation.id,
            ...relation.tags,
            plantOutput: relation.tags?.["plant:output:electricity"] || "",
            plantStatus: getPlantStatus(relation) || "",
          },
          geometry: hull.geometry,
        };

        if (index !== -1) {
          // If the object exists, replace it
          processedStations[index] = newPowerStationFeature;
        } else {
          // If the object doesn't exist, push it
          processedStations.push(newPowerStationFeature);
        }
      }
    });

    return processedStations;
  };

  const fetchZoomedPowerPlants = async (bounds, zoom) => {
    const overpassQuery = `
      [out:json][timeout:50000];
      area[name="Australia"]->.a;
      (
        node["power"="plant"](area.a);
        way["power"="plant"](area.a);
        relation["power"="plant"](area.a);
        node["construction:power"="plant"](area.a);
        way["construction:power"="plant"](area.a);
        relation["construction:power"="plant"](area.a);
      );
      out meta;
    >;
    out meta qt;
    `;

    // Fetch sequentially
    const data = await fetchWithRetry(overpassQuery);

    const geoJsonData = {
      type: "FeatureCollection",
      features: processZoomedPowerStations(data),
    };

    try {
      // Deep clone the object
      const clone = JSON.parse(JSON.stringify(geoJsonData));
      setZoomedPowerStations(geoJsonData);
      storeDataInIndexedDB("zoomedPowerStations", clone);
    } catch (error) {
      setZoomedPowerStations({
        type: "FeatureCollection",
        features: [],
      });
      console.error("Failed to fetch power plants:", error);
    }
  };

  const processZoomedPowerStations = (osmData) => {
    // Step 1: Identify used node IDs
    const usedNodeIds = new Set();

    osmData.elements.forEach((element) => {
      if (element.type === "way" || element.type === "relation") {
        if (element.nodes) {
          element.nodes.forEach((nodeId) => usedNodeIds.add(nodeId));
        }
      }
    });

    // Step 2: Convert OSM data to GeoJSON
    return osmData.elements
      .map((element) => {
        if (element.type === "node" && !usedNodeIds.has(element.id)) {
          // Plot node only if it has not been used in a way or relation
          return {
            type: "Feature",
            properties: {
              id: element.id,
              ...element.tags,
              markerOptions: {
                radius: 0.15, // Circle marker size (15px diameter)
                // fillColor: "#F88C00", //"#D5B60A",
                color: "black", // Border color
                weight: 0.15, // Border width
                fillOpacity: 0.2, // Transparency
              },
            },
            geometry: {
              type: "Point",
              coordinates: [element.lon, element.lat],
            },
          };
        } else if (element.type === "way" || element.type === "relation") {
          // Handle ways and relations
          if (element.nodes) {
            const coordinates = element.nodes
              .map((nodeId) => {
                const node = osmData.elements.find(
                  (el) => el.id === nodeId && el.type === "node"
                );
                if (node) {
                  return [node.lon, node.lat];
                }
                return null;
              })
              .filter(Boolean);

            if (
              coordinates.length > 0 &&
              element.tags?.landuse !== "farmland"
            ) {
              return {
                type: "Feature",
                properties: {
                  id: element.id,
                  ...element.tags,
                },
                geometry: {
                  type: "Polygon",
                  coordinates: [coordinates],
                },
              };
            }
          }
        }
        return null;
      })
      .filter(Boolean);
  };

  async function fetchTransmissionLines() {
    const overpassQuery = `
        [out:json][timeout:50000];
        area[name="Australia"]->.a;
        (
        way["power"="line"](area.a);
        way["power"="minor_line"](area.a);
        way["power"="cable"](area.a);
        way["construction:power"="line"](area.a);
        );
        out geom tags;
      `;

    try {
      const data = await fetchWithRetry(overpassQuery);
      setOSMTransmissionLines(data.elements); // Contains the OSM transmission lines
      setShowGALines(false);
    } catch (error) {
      console.error("Error fetching transmission lines:", error);
      setOSMTransmissionLines([]);
    }
  }

  async function fetchSubstationEquipment() {
    const overpassQuery = `
        [out:json][timeout:50000];
        area[name="Australia"]->.a;
        (
        node["power"="transformer"](area.a);
        node["power"="switch"](area.a);
        node["power"="compensator"](area.a);
        );
        out geom tags;
      `;

    try {
      const data = await fetchWithRetry(overpassQuery);

      //  Convert OSM data to GeoJSON
      const geoJsonData = {
        type: "FeatureCollection",
        features: data.elements
          .map((element) => {
            if (element.type === "node") {
              // Plot node only if it has not been used in a way or relation
              return {
                type: "Feature",
                properties: {
                  id: element.id,
                  ...element.tags,
                  markerOptions: {
                    radius: 0.15, // Circle marker size (15px diameter)
                    // fillColor: "#F88C00", //"#D5B60A",
                    color: "black", // Border color
                    weight: 0.15, // Border width
                    fillOpacity: 0.2, // Transparency
                  },
                },
                geometry: {
                  type: "Point",
                  coordinates: [element.lon, element.lat],
                },
              };
            }
            return null;
          })
          .filter(Boolean),
      };

      setSubstationEquipment(geoJsonData);
    } catch (error) {
      console.error("Error fetching substation equipment:", error);
      setSubstationEquipment({
        type: "FeatureCollection",
        features: [],
      });
    }
  }

  async function fetchGenerators() {
    const overpassQuery = `
      [out:json][timeout:50000];
      area[name="Australia"]->.a;
      (
        node["power"="generator"](area.a)["generator:source"!~"^(wind|solar)$"];
      );
      out geom tags;
      `;

    try {
      const data = await fetchWithRetry(overpassQuery);

      //  Convert OSM data to GeoJSON
      const geoJsonData = {
        type: "FeatureCollection",
        features: data.elements
          .map((element) => {
            if (element.type === "node") {
              // Plot node only if it has not been used in a way or relation
              return {
                type: "Feature",
                properties: {
                  id: element.id,
                  ...element.tags,
                  markerOptions: {
                    radius: 0.15, // Circle marker size (15px diameter)
                    // fillColor: "#F88C00", //"#D5B60A",
                    color: "black", // Border color
                    weight: 0.15, // Border width
                    fillOpacity: 0.2, // Transparency
                  },
                },
                geometry: {
                  type: "Point",
                  coordinates: [element.lon, element.lat],
                },
              };
            }
            return null;
          })
          .filter(Boolean),
      };

      setGenerators(geoJsonData);
    } catch (error) {
      console.error("Error fetching generators:", error);
      setGenerators([]);
    }
  }

  const fetchSubstations = async () => {
    const overpassQuery = `
      [out:json][timeout:50000];
      area[name="Australia"]->.a;
      (
        node["power"="substation"](area.a);
        way["power"="substation"](area.a);
        relation["power"="substation"](area.a);
      );
      out tags geom;
    `;

    const data = await fetchWithRetry(overpassQuery);

    const geoJsonData = {
      type: "FeatureCollection",
      features: data.elements.flatMap((element) => {
        const voltageTag = element.tags?.voltage || "0";
        const capacity = voltageTag.includes(";")
          ? Math.max(...voltageTag.split(";").map(Number))
          : Number(voltageTag);

        const colorStyle = getSubstationColor(capacity);

        let geometry = null;
        let center = null;

        if (element.type === "node") {
          geometry = {
            type: "Point",
            coordinates: [element.lon, element.lat],
          };
          center = [element.lon, element.lat];

          return [
            {
              type: "Feature",
              properties: {
                id: element.id,
                name: element.tags.name || "",
                voltage: capacity,
                ref: element.tags.ref || "",
                substation: element.tags.substation || "",
                center,
              },
              geometry,
            },
          ];
        } else if (element.type === "way" || element.type === "relation") {
          if (element?.geometry && Array.isArray(element.geometry)) {
            geometry = {
              type: "Polygon",
              coordinates: [
                element.geometry.map((coord) =>
                  coord && coord.lon && coord.lat ? [coord.lon, coord.lat] : []
                ),
              ],
            };
            center = getMidpointFromBounds(element?.bounds);
          }

          const fullFeature = {
            type: "Feature",
            properties: {
              id: element.id,
              name: element.tags.name || "",
              ref: element.tags.ref || "",
              voltage: capacity,
              substation: element.tags.substation || "",
              center,
            },
            geometry,
          };

          const pointFeature = {
            type: "Feature",
            properties: {
              id: `${element.id}-point`,
              name: element.tags.name || "",
              ref: element.tags.ref || "",
              voltage: capacity,
              substation: element.tags.substation || "",
              center,
              originalType: element.type, // Store original type for reference
            },
            geometry: {
              type: "Point",
              coordinates: center || [0, 0], // Fallback if center is null
            },
          };

          return [fullFeature, pointFeature];
        }

        return []; // If the element type is unknown, return an empty array
      }),
    };

    setSubstations(geoJsonData);
  };

  let getDUIDDetails = async () => {
    let response = await fetch(`/map/duid-details`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "JWT " +
          String(JSON.parse(localStorage.getItem("authTokens"))?.access),
      },
    });

    let data = await response.json();

    if (response.status === 200) {
      setDUIDDetails(data);
    } else {
      setDUIDDetails([]);
    }
  };

  let getStationToOSMMap = async () => {
    let response = await fetch(`/map/station-to-osm`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "JWT " +
          String(JSON.parse(localStorage.getItem("authTokens"))?.access),
      },
    });

    let data = await response.json();

    if (response.status === 200) {
      setStationToOSMMap(data);
    } else {
      setStationToOSMMap([]);
    }
  };

  let contextData = {
    generators: generators,
    substations: substations,
    showGALines: showGALines,
    DUIDDetails: DUIDDetails,
    groupStations: groupStations,
    showSatellite: showSatellite,
    powerStations: powerStations,
    NEMAssetsOnly: NEMAssetsOnly,
    stationToOSMMap: stationToOSMMap,
    zoomedPowerStations: zoomedPowerStations,
    substationEquipment: substationEquipment,
    OSMTransmissionLines: OSMTransmissionLines,
    powerStationsBoundaries: powerStationsBoundaries,
    getDUIDDetails: getDUIDDetails,
    getStationToOSMMap: getStationToOSMMap,
    setShowGALines: setShowGALines,
    setNEMAssetsOnly: setNEMAssetsOnly,
    setShowSatellite: setShowSatellite,
    setGroupStations: setGroupStations,
  };

  useEffect(() => {
    const initalLoad = async () => {
      getDUIDDetails();
      getStationToOSMMap();
      if (!powerStations) {
        fetchPowerPlants();
      }
      fetchPowerPlantsBoundaries();
      fetchTransmissionLines();
      fetchSubstations();
      fetchSubstationEquipment();
      fetchGenerators();
      fetchZoomedPowerPlants();
    };

    initalLoad();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <MapContext.Provider value={contextData}>{children}</MapContext.Provider>
  );
};
