import { SnackbarProvider } from "notistack";
import { useEffect } from "react";
import ReactGA from "react-ga4";
import { hotjar } from "react-hotjar";
import { Route, Routes, useLocation } from "react-router-dom";
import "./App.css";
import { AuthProvider } from "./context/AuthContext";
import { DataProvider } from "./context/DataContext";
import { MapProvider } from "./context/MapContext";
import { SettingsProvider } from "./context/SettingsContext";
import Layout from "./hocs/Layout";
import SubLayout from "./hocs/SubLayout";
import ThemeLayout from "./hocs/ThemeLayout";
import Account from "./scenes/account/";
import ConstraintList from "./scenes/constraints/ConstraintList";
import ConstraintSetList from "./scenes/constraints/ConstraintSetList";
import CreateUserForm from "./scenes/createuserform/";
import Dashboard from "./scenes/dashboard";
import Map from "./scenes/map";
import HighImpactOutages from "./scenes/outages/HighImpactOutages";
import NetworkOutageAll from "./scenes/outages/NetworkOutageAll";
import NetworkOutageSet from "./scenes/outages/NetworkOutageSet";
import Portfolio from "./scenes/portfolio";
import AdminOnly from "./scenes/settings/AdminOnly";
import Activate from "./scenes/unauthorized/Activate";
import DemoPage from "./scenes/unauthorized/DemoPage";
import PrivacyPolicy from "./scenes/unauthorized/PrivacyPolicy";
import ResetPasswordConfirm from "./scenes/unauthorized/ResetPasswordConfirm";
import SignIn from "./scenes/unauthorized/SignIn";
import SignUp from "./scenes/unauthorized/SignUp";
import TermsAndConditions from "./scenes/unauthorized/TermsAndConditions";
import MarketingPage from "./scenes/unauthorized/marketing-page/MarketingPage";
import PrivateRoutes from "./utils/PrivateRoutes";
import PublicRoutes from "./utils/PublicRoutes";

function App() {
  const location = useLocation();

  useEffect(() => {
    // Initialize GA with your tracking ID
    ReactGA.initialize("G-B0WFFS12ED");

    // Initialize Hotjar with your site ID and version
    hotjar.initialize(5230491, 6);
  }, []);

  useEffect(() => {
    // Log the initial pageview
    ReactGA.send({ hitType: "pageview", page: location.pathname });
    // Update SPA state
    hotjar.stateChange(location.pathname);
  }, [location]);

  return (
    <div className="App">
      <SnackbarProvider maxSnack={3} autoHideDuration={6000}>
        <AuthProvider>
          <SettingsProvider>
            <DataProvider>
              <MapProvider>
                <ThemeLayout>
                  <Layout>
                    <SubLayout>
                      <Routes>
                        <Route element={<PrivateRoutes />}>
                          <Route
                            exact
                            path="/dashboard"
                            element={<Dashboard />}
                          />
                          <Route exact path="/map" element={<Map />} />
                          {/* <Route
                            exact
                            path="/dashboard_v2"
                            element={<DashboardV2 />}
                          /> */}
                          <Route
                            exact
                            path="/portfolio"
                            element={<Portfolio />}
                          />
                          <Route
                            exact
                            path="/constraint/list"
                            element={<ConstraintList />}
                          />
                          <Route
                            exact
                            path="/constraint_set/list"
                            element={<ConstraintSetList />}
                          />
                          <Route
                            exact
                            path="/network/constraintsets"
                            element={<NetworkOutageSet />}
                          />
                          <Route
                            exact
                            path="/network/all-outages"
                            element={<NetworkOutageAll />}
                          />
                          <Route
                            exact
                            path="/network/high-impact-outages"
                            element={<HighImpactOutages />}
                          />
                          <Route exact path="/account" element={<Account />} />
                          <Route
                            exact
                            path="/createuser"
                            element={<CreateUserForm />}
                          />
                          <Route
                            exact
                            path="/settings-admin"
                            element={<AdminOnly />}
                          />
                        </Route>
                        <Route
                          exact
                          path="/terms-and-conditions"
                          element={<TermsAndConditions />}
                        />
                        <Route
                          exact
                          path="/privacy-policy"
                          element={<PrivacyPolicy />}
                        />
                        <Route element={<PublicRoutes />}>
                          <Route exact path="/" element={<MarketingPage />} />
                          <Route exact path="/demo" element={<DemoPage />} />
                          <Route exact path="/login" element={<SignIn />} />
                          <Route exact path="/signup" element={<SignUp />} />
                          <Route
                            exact
                            path="/activate/:uid/:token"
                            element={<Activate />}
                          />
                          <Route
                            exact
                            path="/password/reset/confirm/:uid/:token"
                            element={<ResetPasswordConfirm />}
                          />

                          <Route
                            path="*"
                            element={
                              <div
                                style={{
                                  position: "absolute",
                                  top: "0",
                                  left: "0",
                                  padding: "20px",
                                }}
                              >
                                <h5>Page not found</h5>
                              </div>
                            }
                          />
                        </Route>
                      </Routes>
                    </SubLayout>
                  </Layout>
                </ThemeLayout>
              </MapProvider>
            </DataProvider>
          </SettingsProvider>
        </AuthProvider>
      </SnackbarProvider>
    </div>
  );
}

export default App;
