import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { makeStyles } from "@mui/styles";

// Custom styles for positioning the floating button and legend items
const useStyles = makeStyles(() => ({
  fab: {
    position: "fixed",
    top: "2vh",
    right: 20,
    zIndex: 1000, // Ensure it stays on top
  },
  colorBox: {
    display: "inline-block",
    width: 20,
    height: 10,
    marginRight: 8,
    borderRadius: 2,
  },
  legendItem: {
    display: "flex",
    alignItems: "center",
    marginBottom: 8,
  },
  icon: {
    width: 20,
    height: 20,
    marginRight: 8,
  },
}));

const TransmissionLineLegend = () => {
  const classes = useStyles();

  return (
    <>
      {/* Power Line Colors Section */}
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          Transmission Lines
        </AccordionSummary>

        <AccordionDetails>
          <div className={classes.legendItem}>
            <span
              className={classes.colorBox}
              style={{ backgroundColor: "#4F00B5" }}
            ></span>
            <span>HVDC</span>
          </div>
          <div className={classes.legendItem}>
            <span
              className={classes.colorBox}
              style={{ backgroundColor: "#7326D3" }}
            ></span>
            <span>≥ 400 kV</span>
          </div>
          <div className={classes.legendItem}>
            <span
              className={classes.colorBox}
              style={{ backgroundColor: "#B54EB2" }}
            ></span>
            <span>≥ 330 kV</span>
          </div>
          <div className={classes.legendItem}>
            <span
              className={classes.colorBox}
              style={{ backgroundColor: "#C73030" }}
            ></span>
            <span>≥ 275 kV</span>
          </div>
          <div className={classes.legendItem}>
            <span
              className={classes.colorBox}
              style={{ backgroundColor: "#E55C00" }}
            ></span>
            <span>≥ 220 kV</span>
          </div>
          <div className={classes.legendItem}>
            <span
              className={classes.colorBox}
              style={{ backgroundColor: "#B55D00" }}
            ></span>
            <span>≥ 132 kV</span>
          </div>
          <div className={classes.legendItem}>
            <span
              className={classes.colorBox}
              style={{ backgroundColor: "#B59F11" }}
            ></span>
            <span>≥ 66 kV</span>
          </div>
          <div className={classes.legendItem}>
            <span
              className={classes.colorBox}
              style={{ backgroundColor: "#56B555" }}
            ></span>
            <span>≥ 33 kV</span>
          </div>
          <div className={classes.legendItem}>
            <span
              className={classes.colorBox}
              style={{ backgroundColor: "#6F97B8" }}
            ></span>
            <span>≥ 22 kV</span>
          </div>
          <div className={classes.legendItem}>
            <span
              className={classes.colorBox}
              style={{ backgroundColor: "#7A7A85" }}
            ></span>
            <span>&lt; 22 kV</span>
          </div>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default TransmissionLineLegend;
