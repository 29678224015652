import "esri-leaflet"; // Import Esri Leaflet
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import { useContext, useEffect, useState } from "react";
import { GeoJSON, useMap } from "react-leaflet";
import MapContext from "../../../../context/MapContext";

const ZoomedPowerStations = ({ zoomLevel }) => {
  const map = useMap();
  const { zoomedPowerStations } = useContext(MapContext);
  const [powerStations, setPowerStations] = useState();

  useEffect(() => {
    if (!zoomedPowerStations) return;

    const bounds = map.getBounds();

    // ✅ Filter power stations to only include those within the current bounds
    const filtered = zoomedPowerStations.features?.filter((feature) => {
      let latlng;
      if (feature.geometry.type === "Point") {
        latlng = L.latLng(
          feature.geometry.coordinates[1],
          feature.geometry.coordinates[0]
        );
      } else if (feature.geometry.type === "Polygon") {
        const center = feature.geometry.coordinates[0][0]; // Approximate center
        latlng = L.latLng(center[1], center[0]);
      }
      return latlng && bounds.contains(latlng);
    });

    setPowerStations({ type: "FeatureCollection", features: filtered });
  }, [zoomedPowerStations, map]);

  useEffect(() => {
    if (!powerStations) return;

    const updateTooltips = () => {
      const zoom = map.getZoom();

      // Define our thresholds
      const tooltipThreshold = 13; // At zoom >= 13, show ref/name
      const outputThreshold = 16; // At zoom >= 15, also add output

      powerStations.features.forEach((feature) => {
        if (!feature._layer) return;
        const layer = feature._layer;
        // Determine the layer's latlng
        let latlng;
        if (typeof layer.getLatLng === "function") {
          latlng = layer.getLatLng();
        } else if (typeof layer.getBounds === "function") {
          latlng = layer.getBounds().getCenter();
        } else {
          return;
        }
        const isInBounds = map.getBounds().contains(latlng);
        let newContent = "";
        if (
          zoom >= tooltipThreshold &&
          isInBounds &&
          feature.geometry.type === "Point"
        ) {
          // Start with either ref or name (if they exist)
          let baseContent = "";
          if (feature.properties.ref) {
            baseContent = `<strong>${feature.properties.ref}</strong>`;
          } else if (feature.properties.name) {
            baseContent = `<strong>${feature.properties.name}</strong>`;
          }
          // If zoom is high enough, add output information if available
          if (zoom >= outputThreshold) {
            // Try getting generator output or plant output (adjust as needed)
            const output =
              feature.properties["generator:output:electricity"] || "";
            if (output) {
              baseContent += `<br/><strong>${output}</strong>`;
            }
          }
          newContent = baseContent;
        }
        // Update the tooltip's content
        layer.setTooltipContent(newContent);
      });
    };

    map.on("zoomend", updateTooltips);
    map.on("move", updateTooltips);
    updateTooltips(); // Run initially
    return () => {
      map.off("zoomend", updateTooltips);
      map.off("move", updateTooltips);
    };
  }, [map, powerStations]);

  return (
    <>
      {powerStations && (
        <GeoJSON
          key={`${zoomLevel}-${map.getBounds()}`} // Force re-render on zoomLevel change
          data={powerStations}
          pointToLayer={(feature, latlng) => {
            if (
              feature.geometry.type === "Point" &&
              feature.properties.markerOptions
            ) {
              if (zoomLevel >= 13) {
                const windIcon = new L.Icon({
                  iconUrl:
                    "https://cdn-icons-png.flaticon.com/512/15317/15317996.png",
                  iconSize: [12, 12],
                  iconAnchor: [6, 6],
                  popupAnchor: [0, -6],
                });
                return L.marker(latlng, { icon: windIcon });
              } else {
                // Return a custom styled CircleMarker
                return L.circleMarker(latlng, feature.properties.markerOptions);
              }
            }
          }}
          style={(feature) => {
            if (feature.geometry.type === "Polygon") {
              return {
                color: "black", // Border color (outline)
                fillColor: "grey", // Fill color based on voltage
                weight: 2, // Border width
                fillOpacity: 0.4, // Transparency inside the polygon
              };
            }
            return {};
          }}
          onEachFeature={(feature, layer) => {
            const { id, name, operator, power, manufacturer, ref } =
              feature.properties;
            if (power === "plant") {
              const popupContent = `
              <div>
                <strong>Operator</strong> ${operator || ""} <br/>
                <strong>Name:</strong> ${name || ""} <br/>
                <strong>Fuel:</strong> ${
                  feature.properties["plant:source"] || ""
                } <br/>
                <strong>Rating:</strong> ${
                  feature.properties["plant:output:electricity"] || ""
                } <br/>
              </div>
            `;
              layer.bindPopup(popupContent);
            } else if (power === "generator") {
              const popupContent = `
              <div>
                <strong>Operator</strong> ${operator || ""}  <br/>
                <strong>Generator Type:</strong> ${
                  feature.properties["generator:method"] || ""
                } <br/>
                <strong>Manufacturer:</strong> ${manufacturer || ""} <br/>
                <strong>Manufacturer Type:</strong> ${
                  feature.properties["manufacturer:type"] || ""
                }  <br/>
                <strong>Generator Rating:</strong> ${
                  feature.properties["generator:output:electricity"] || ""
                } <br/>
                <strong>Generator Reference:</strong> ${ref || ""}  <br/>
          
              </div>
            `;
              layer.bindPopup(popupContent);
            }

            // Add a click event listener to log properties when a line is clicked
            layer.on("click", () => {
              console.log("Clicked feature properties:", feature.properties);
            });
            // Define our thresholds
            const tooltipThreshold = 13; // At zoom >= 13, show ref/name
            const outputThreshold = 16; // At zoom >= 16, also add output

            let newContent = "";

            // Determine latlng depending on layer type
            let latlng;
            if (typeof layer.getLatLng === "function") {
              latlng = layer.getLatLng();
            } else if (typeof layer.getBounds === "function") {
              latlng = layer.getBounds().getCenter();
            } else {
              return;
            }
            const isInBounds = map.getBounds().contains(latlng);

            // Start with either ref or name (if they exist)
            let baseContent = "";
            if (feature.properties.ref) {
              baseContent = `<strong>${feature.properties.ref}</strong>`;
            } else if (feature.properties.name) {
              baseContent = `<strong>${feature.properties.name}</strong>`;
            }
            // If zoom is high enough, add output information if available
            if (map.getZoom() >= outputThreshold) {
              // Try getting generator output or plant output (adjust as needed)
              const output =
                feature.properties["generator:output:electricity"] || "";
              if (output) {
                baseContent += `<br/><strong>${output}</strong>`;
              }
            }
            newContent = baseContent;

            // Initially check if the tooltip should be shown
            if (
              map.getZoom() >= tooltipThreshold &&
              isInBounds &&
              feature.geometry.type === "Point"
            ) {
              layer.bindTooltip(`<strong>${newContent}</strong>`, {
                permanent: true,
                direction: "bottom",
                offset: [0, 1],
                className: "generator-label",
              });
            }

            // Store layer reference in the feature
            feature._layer = layer;
          }}
        />
      )}
    </>
  );
};

export default ZoomedPowerStations;
